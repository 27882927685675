import _ from 'underscore';
import { Message } from "element-ui";

const common = {
    // 过滤阿拉伯数字为中文数字
    getNumStr(number) {
        let num = ['一', '二', '三', '四', '五', '六', '七', '八', '九', ];
        let double = ['十'];
        if (number < 10) {
            let index = parseInt(number) - 1;
            return num[index];
        } else if (number > 10 && number < 20) {
            let sub = parseInt(number) - 10;
            let str = num[sub - 1];
            return double[0] + str;
        }
    },
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    translateNumber(index) { //数组下标
        let numberArr = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
        if (index < 10) {
            return numberArr[index];
        } else if (index >= 10 && index < 20) {
            let sub = index - 10;
            if (sub < 9) {
                return '十' + numberArr[sub];
            } else {
                return '二十';
            }
        }
    },
    translateNumberByObject(object, key) {
        let keysArr = [];
        _.map(object, (item, index) => {
            if (item.length) {
                keysArr.push(index);
            }
        });
        let _index = keysArr.indexOf(key);
        return this.translateNumber(_index);

    },
    formatAlph(index) {
        const numbers = {
            '1': 'A',
            '2': 'B',
            '3': 'C',
            '4': 'D',
            '5': 'E',
            '6': 'F',
        }
        return numbers[index];
    },
    /*
     * content: 课程内容
     * reslist: 资源列表
     * papers:  试卷列表
     * projects: 项目列表
     * */
    filterResource(content, reslist, papers, projects, format={}) {
        let chapters = [];
        _.each(content, (chapter, index) => {
            let chapterTmp = {};
            chapterTmp.name = chapter.name;
            chapterTmp.type = chapter.type;
            chapterTmp.children = [];
            if (chapter.children.length) {
                _.each(chapter.children, (section, node_index) => {
                    if (format.res_key && format.res_key.length > 0) {
                        let sectionTmp = {};
                        sectionTmp.name = section.name;
                        sectionTmp.type = section.type;
                        sectionTmp.children = [];
                        if (section.children.length) {
                            let ids = section.children
                            if (ids.length) {
                                //好像不用章和节进行判断就可以筛选出资源
                                //如果用index去比对的话，是不准确的
                                //暂时保留
                                // if (index === format.chapter_key && node_index === format.node_key) {
                                    _.each(format.res_key, (_item) => {
                                        let val = _.find(ids, (_val) => {
                                            return Number(_val.id) === Number(_item.id);
                                        });
                                        if(val != undefined){
                                            let _value = this.formatResource(val, reslist, papers, projects);
                                            sectionTmp.children.push(_value);
                                        }
                                    });
                                // }
                            }
                        }
                        chapterTmp.children.push(sectionTmp);
                    } else {
                        let sectionTmp = {};
                        sectionTmp.name = section.name;
                        sectionTmp.type = section.type;
                        sectionTmp.children = [];
                        if (section.children && section.children.length) {
                            let ids = section.children
                            if (ids.length) {
                                _.each(ids, (item) => {
                                    let _value = this.formatResource(item, reslist, papers, projects);
                                    sectionTmp.children.push(_value);
                                });
                            }
                        }
                        chapterTmp.children.push(sectionTmp);
                    }
                });
            }
            chapters.push(chapterTmp);
        });
        return chapters;
    },
    //格式化资源
    formatResource(val, reslist, papers, projects) {
        if (Number(val.type) === 3) {
            let val_tmp = _.find(reslist, { resource_id: parseInt(val.id) });
            if (val_tmp) {
                val.resource_id = Number(val.id);
                val.name = val_tmp.name ? val_tmp.name : val_tmp.resource_title;
                val.resource_file_type = val_tmp.resource_file_type;
                val.file_preview_path = val_tmp.file_preview_path;
                val.children = [];
                return val;
            }
        }
        if (Number(val.type) === 4) {
            let val_tmp = _.find(papers, { id: parseInt(val.id) });
            if (val_tmp) {
                val.id = Number(val.id);
                val.name = val_tmp.name ? val_tmp.name : val_tmp.paper_name;
                val.children = [];
                return val;
            }
        }
        if (Number(val.type) === 5) {
            let val_tmp = _.find(projects, { id: parseInt(val.id) });
            if (val_tmp) {
                val.id = Number(val.id);
                val.name = val_tmp.name ? val_tmp.name : val_tmp.project_title;
                val.children = [];
                return val;
            }
        }
    },
    /*格式化章节资源内容，同时验证必须为章-节-资源格式*/
        formatChapters(chapters) {
        let trueFormat = true;
        let trueTier = true;
        chapters.forEach((ele) => {
            if (ele.children.length) {
                ele.children.forEach((sec) => {
                    if (parseInt(sec.type) !== 2) {
                        trueFormat = false;
                    }
                    if (sec.children.length) {
                        sec.children.forEach((resource) => {
                            if (resource !=null) {
                                if(resource.children.length > 0){
                                    trueTier = false;
                                }
                            }
                        })
                    }
                })
            }
        });
        return trueFormat && trueTier;
    },
    axiosErrorMsg(err) {
        if (err.response) {
            err.response.data.msg ? Message.error(err.response.data.msg) : '服务异常';
        } else {
            err.message ? Message.error(err.message) : '服务异常';
        }
    },
    /*章节资源内容必须包含资源*/
    checkChapters(chapters) {
        let trueFormat = true;
        let trueTier = true;
        chapters.forEach((ele) => {
            if(ele.children.length == undefined || ele.children.length == 0){
                trueFormat = false;
            } else if (ele.children.length > 0) {
                ele.children.forEach((sec) => {
                    if(sec.children.length == undefined || sec.children.length == 0){
                        trueTier = false;
                    }
                })
            }
        });
        return trueFormat && trueTier;
    },
    arrayUnique(array=[]) {
        let list = [];
        array.map(item => {
            const value= item[0]
            if (list.indexOf(value) === -1) {
                list.push(value)
            }
        })
        return list
    }
};
export default common;
