<template>
    <div class="quality-course-container">
        <HeaderModule :islogin="isLogin"></HeaderModule>
        <div class="quality-course-content">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import HeaderModule from 'components/qualityCourseLayout/HeaderModule';

    export default {
        name: "index",
        data() {
            return {
                isLogin: true
            }
        },
        components: {
            HeaderModule
        }
    }
</script>

<style scoped lang="scss">
    .quality-course-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
        /*.course-list {*/
        .quality-course-content {
            flex: 1;
            height: calc(100% - 60px);
            /*::v-deep .el-scrollbar__wrap {*/
            /*    overflow-x: hidden;*/
            /*    .el-scrollbar__view {*/
            /*        padding-bottom: 50px;*/
            /*    }*/
            /*}*/
        }
        /*}*/
    }
</style>