<template>
    <div id="content" style="width:100%">
        <canvas id="canvas" ref="canvas" style="width:1000px;height:600px"></canvas>
    </div>
</template>

<script>
    export default {
        name: 'Jtopo',
        props: {
            fishboneData: {
                type: Object
            }
        },
        mounted () {
            this.initTopo()
        },
        methods: {
            initTopo () {
                let canvas = this.$refs.canvas
                if(this.fishboneData){
                    let mfb = new MakFishBone(canvas, {data: this.fishboneData})
                    mfb.start()
                }
            }
        }
    }
</script>

<style scoped lang="scss">

</style>