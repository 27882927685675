import axios from 'axios';
import { Loading } from 'element-ui';

const instance = axios.create({});

let loadingInstance = null;

/* 请求拦截器 */
instance.interceptors.request.use((config) => {
    if (localStorage.getItem('token')) {
        config.headers.Authorization = localStorage.getItem('token');
    } else if(localStorage.getItem('sToken')) {
        config.headers.Authorization = localStorage.getItem('sToken');
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

/* 响应拦截器 */

instance.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    return Promise.reject(error);
});


const Http = {};


/* 上传文件 */
Http.axiosFile = (url, formData, callback, reject) => {
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    instance.post(url, formData, config).then(callback).catch(reject);
}
/* 上传文件 自定义配置*/
Http.axiosFileConfig = (url, formData, config, callback, reject) => {
    config.headers = {
        'Content-Type': 'multipart/form-data'
    }
    instance.post(url, formData, config).then(callback).catch(reject);
}

export default Http;
