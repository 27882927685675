import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        undone: 0,
        lesson: {
            course_id: 0,
            course_name: '',
            course_desc: '',
            course_cover: '',
            resource_class_id: 0,
            parent_id: 0,
            course_type: 1,
            course_content: '',
            sort: 255
        },
        initData: {
            course_id: 0,
            course_name: '',
            course_desc: '',
            course_cover: '',
            resource_class_id: 0,
            parent_id: 0,
            course_type: 1,
            course_content: '',
            sort: 255
        },
        refreshStatus: 1,
        resources: [],
        courseAttr: {
            course_name: '',
            chapter_name: '',
            node_name: '',
            chapter_index: 0,
            node_index: 0,
            resource_type: 0, //平台资源 resource_type_id : 课件 => 1 , 教材 => 2, 微课 => 3, 动画 => 4, 配套材料 => 5, 教案 => 6
            course_rtype: 0,  //课程内容中的标识： 平台资源：3，小测：4，项目：5；配套材料（学校管理员和学校教师自己上传的资源）：6
        },
        courseEditInfo: {},
    },
    getters: {
        getLesson: state => {
            return state.lesson;
        },
        initData: state => {
            return state.initData;
        },
        getRefreshStatus: state => {
            return state.refreshStatus;
        },
        resourcesList: state => {
            return state.resources;
        },
        courseAttrGetter: state => {
            return state.courseAttr;
        },
        courseEditInfoGetter: state => {
            return state.courseEditInfo;
        },
    },
    mutations: {
        setLesson(state, playload) {
            state.lesson = {...state.lesson, ...playload};
        },
        setRefresh(state, val) {
            state.refreshStatus = val;
        },
        setResources(state, playload) {
            state.resources = playload;
        },
        setCourseAttr(state, playload) {
            state.courseAttr = {...state.courseAttr, ...playload};
        },
        setCourseEdit(state, playload) {
            state.courseEditInfo = {...state.courseEditInfo, ...playload};
        },

    },
    actions: {
        setLessonContent({commit}, args) {
            commit('setLesson', args);
        },
        setRefreshVal({commit}, args) {
            commit('setRefresh', args);
        },
        setResourceList({commit}, args) {
            commit('setResources', args);
        },
        setCourseAttrC({commit}, args) {
            commit('setCourseAttr', args);
        },
        setCourseEditInfo({commit}, args) {
            commit('setCourseEdit', args)
        },
    },
    modules: {},
    plugins: [createPersistedState()],
})
