<template>
    <div class="quality-course-container">
        <HeaderHome></HeaderHome>
        <div class="quality-course-content">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import HeaderHome from 'components/qualityCourseLayout/HeaderHome';

    export default {
        components: {
            HeaderHome
        },
		created() {
			if (localStorage.getItem('role') === 4) {
				this.$route.push('/qualityCourse/index');
			}
		}
    }
</script>

<style scoped lang="scss">
    .quality-course-container {
		position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
        overflow: hidden;
        /*.course-list {*/
            .quality-course-content {
                flex: 1;
                height: calc(100% - 60px);
                /*::v-deep .el-scrollbar__wrap {*/
                /*    overflow-x: hidden;*/
                /*    .el-scrollbar__view {*/
                /*        padding-bottom: 50px;*/
                /*    }*/
                /*}*/
            }
        /*}*/
    }
</style>