<template>
    <div class="quality-course-container">
        <HeaderModule></HeaderModule>
        <div class="quality-course-content">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import HeaderModule from 'components/qualityCourseLayout/HeaderModule';

    export default {
        name: "index",
        components: {
            HeaderModule
        }
    }
</script>

<style scoped lang="scss">
    .quality-course-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
        .quality-course-content {
            flex: 1;
            height: 1%;
            background-color: #F2F2F2;
            overflow: auto;
        }
    }
</style>