<template>
    <div class="course-ware-content" :native="false">
        <el-scrollbar class="course-ware-list">
            <div class="course-ware">
                <div class="course-ware-item" v-for="item in courseWareList" v-if="courseWareList.length > 0" @click="viewDetail(item)">
                    <div class="course-ware-cover">
                        <img src="../../../assets/img/image/course_ware.png" alt="" class="course-ware-icon">
                    </div>
                    <span class="course-ware-name" :title="item.resource_title">{{item.resource_title}}</span>
                </div>
                <div v-if="courseWareList.length === 0" class="no-data">
                    <span>暂无数据</span>
                </div>
            </div>
        </el-scrollbar>
        <ResourceDialog :dialogVisible="dialogVisible" :src="src" @closeDialog="closeDialog"></ResourceDialog>
    </div>
</template>

<script>
    import ResourceDialog from "../../components/ResourceDialog";

    export default {
        name: "CourseWare",
        components: {
            ResourceDialog
        },
        props: ['searchInput'],
        data() {
            return {
                //课件列表
                courseWareList: [],
                src: "",
                dialogVisible: false,
            }
        },
        created() {
            this.getCourseInfo();
        },
        watch: {
            searchInput(d) {
                if(d !== ""){
                    let arr = [];
                    for (let i = 0; i < this.courseWareList.length; i++) {
                        if (this.courseWareList[i].resource_title.indexOf(d) !== -1) {
                            arr.push(this.courseWareList[i]);
                        }
                    }
                    this.courseWareList = arr;
                } else {
                    this.courseWareList = [];
                    this.getCourseInfo();
                }
            }
        },
        methods: {
            //获取课件列表
            getCourseInfo() {
                let params = {
                    id: this.$route.query.course_id
                };
                this.$shttp.axiosGetBy(this.$api.courseinfo, params, (res) => {
                    if (res.code === 200) {
                        console.log('课件',res.data.reslist)
                        for (let i = 0; i < res.data.reslist.length; i++) {
                            if (res.data.reslist[i].resource_type_id === 1) {
                                this.courseWareList.push(res.data.reslist[i]);
                            }
                        }
                    }
                }, (error) => {
                    console.log(error);
                })
            },
            viewDetail(item) {
                this.src = item.file_preview_path;
                this.dialogVisible = true;
            },
            closeDialog() {
                this.src = '';
                this.dialogVisible = false
            },
        }
    }
</script>

<style scoped lang="scss">
    .course-ware-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .course-ware-list {
            flex: 1;
            width: 100%;
            height: 1%;
            background-color: #fff;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .course-ware {
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;
                .course-ware-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 30px 16px 0;
                    cursor: pointer;
                    border-radius: 10px;
                    &:hover {
                        transform: scale(1.2);
                        transition: 1s;
                    }
                    .course-ware-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 107px;
                        height: 107px;
                    }
                    img.course-ware-icon {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 5px;
                    }
                    span.course-ware-name {
                        color: #333;
                        font-size: 12px;
                        margin-top: 10px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 107px;
                        text-align: center;
                    }
                }
            }
        }
        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
            background-color: #fff;
            .module {
                flex: 1;
                width: 1%;
                ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                    white-space: nowrap;
                }
            }
            .module-list {
                padding: 6px;
                display: flex;
                .module-item {
                    display: inline-block;
                    min-width: 60px;
                    min-height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 10px;
                    margin-right: 15px;
                }
            }
            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 4px;
                color: #409EFF;
                font-size: 14px;
                line-height: 60px;
                text-align: center;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
</style>