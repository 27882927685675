<template>
    <div id="minder-container">
        <div class="btn-box">
            <el-button type="primary" @click="downloadKnowledge">下 载</el-button>
        </div>
    </div>
</template>

<script>
    import kity from 'kity'
    import kityminder from 'kityminder-core'

    export default {
        props: ['knowledgeData'],
        data() {
            return {
                minder: null,
                newData: "",
                showData: {
                    root: {
                        data: {
                            text: ''
                        },
                        children: []
                    }
                },
            };
        },
        mounted() {
            this.formatData();
        },
        methods: {
            handleClose() {
                this.$emit('showKnowledge', false);
            },
            formatData() {
                this.newData = JSON.parse(JSON.stringify(this.knowledgeData));
                this.getFormat(this.newData)
                this.showData.root.children = this.newData;
                this.minder = new window.kityminder.Minder({
                    renderTo: '#minder-container'
                });
                this.minder.importJson(this.showData);
            },
            getFormat(list) {
                list.forEach(ele => {
                    ele.data = {
                        text: ele.name
                    }
                    if (ele.children && ele.children.length > 0) {
                        this.getFormat(ele.children)
                    }
                    if (ele.children.children && ele.children.children.length > 0) {
                        this.getFormat(ele.children)
                    }
                })
            },
            downloadKnowledge() {
                this.minder.exportData('png').then(function (content) {
                    console.log(content,999)
                    //将base64编码转换为blob对象
                    let arr = content.split(',');
                    //注意base64的最后面中括号和引号是不转译的
                    let _arr = arr[1].substring(0, arr[1].length - 2);
                    let mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(_arr),
                        n = bstr.length,
                        u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    let blob = new Blob([u8arr], {
                        type: mime
                    });
                    let a = document.createElement("a"); //建立标签，模拟点击下载
                    a.download = '知识图谱.png';
                    a.href = URL.createObjectURL(blob);
                    a.click();
                });
            },
        }
    };
</script>

<style scoped lang="scss">

    #minder-container {
        width: 100%;
        height: 600px;
        /*position: absolute;*/
        background: #ffffff !important;
        /*left: 10px;*/
        /*top: 10px;*/
        /*bottom: 10px;*/
        /*right: 10px;*/
        position: relative;

        .btn-box {
            position: absolute;
            bottom: 0;
            left: 50%;
        }
    }

</style>