<template>
    <div class="featured-content">
        <div class="featured-index">
            <div class="featured-left">
                <div class="featured-item">
                    <div class="featured-list">
                        <div class="featured-list-item" v-for="(item,index) in featuredCourseList" :key="index"
                             @click="getResourceList(item.index)">
                            <el-popover
                                    popper-class="temp-popover"
                                    placement="bottom"
                                    width="200"
                                    trigger="click">
                                <div slot="reference" class="inner-box">
                                    <div class="item-cover course-ware" :class="item.class">
                                        <!--                                    <i class="iconfont" v-html="item.icon"></i>-->
                                        <img :src="item.src" alt="">
                                    </div>
                                    <span class="item-name">{{item.title}}</span>
                                </div>
                                <div class="popover-item" v-for="(item,index) in showResourseList" :key="index">
                                    <span @click="goToCourseWare(item)">{{item.title}}</span>
                                </div>
                                <div class="no-data" v-if="showResourseList.length === 0">暂无数据</div>
                            </el-popover>
                        </div>
                    </div>
                </div>
                <div class="featured-topbox">
                    <span>资源类型：</span>
                    <span :class="classId === 0 ? 'class-item' : ''" @click="changeType(0)">全部</span>
                    <span :class="classId === item.id ? 'class-item' : ''" @click="changeType(item.id)"
                          v-for="(item,index) in newTypeList" :key="index">{{item.name}}</span>
                </div>
                <el-scrollbar class="featured-top" :native="false">
                    <div class="featured-mainbox">
                        <div class="mainbox-list" v-if="showPeitao.length > 0">
                            <div class="dragg-box">
                                <div class="mainbox-list-item" v-for="(item,index) in showPeitao" @click="goToCourseWare(item)" :key="index">
                                    <div class="pic-box" v-if="item.material_type_id">
                                        <img :src="newTypePic[item.material_type_id - 1].src" alt="">
                                    </div>
                                    <div class="pic-box" v-else>
                                        <img :src="newTypePic[item.material_type_id].src" alt="">
                                    </div>
                                    <div class="item-title">{{item.resource_title}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mainbox-list" v-else>
                            <div class="no-data">暂无数据</div>
                        </div>
                        <div class="page-box">
                            <el-pagination
                                    v-if="showPeitao.length > 0"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="1"
                                    :page-size="10"
                                    layout="prev, pager, next, jumper"
                                    :total="cPeitao.length">
                            </el-pagination>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <el-scrollbar class="featured-right" :native="false">
                <div class="item-title">
                    <span class="text">应用中心</span>
                </div>
                <div class="app-list">
                    <div class="app-item" v-for="(item,index) in appList" @click="viewAppDetail(item)" :key="index">
                        <div class="app-cover">
                            <img v-if="item.logo !== null" :src="item.logo.url" alt="" class="app-icon">
                            <img v-else src="../../../assets/img/image/no_cover.png" alt="" class="app-icon">
                        </div>
                        <span class="app-name" :title="item.app_name">{{item.app_name}}</span>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <el-dialog title="欢迎使用yikeSoft" :visible.sync="dialogAppDetail" width="800px" center class="app-detail-dialog"
                   :close-on-click-modal="false">
            <div class="app-detail-content">
                <h1 class="title">欢迎使用yikeSoft</h1>
                <div class="content">
                    <span class="h2">{{appInfo.appTitle}}</span>
                    <span class="soft-introduction">软件功能介绍：</span>
                    <div class="soft-content">{{appInfo.appContent}}</div>
                </div>
            </div>
            <div slot="footer" class="info-dialog-footer">
                <a :href="appInfo.home_link" target="_blank" class="home">首页登录</a>
                <a :href="appInfo.shop_link" target="_blank" class="shop">商家登录</a>
                <a :href="appInfo.teacher_link" target="_blank" class="shop">教师登录</a>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import _ from "underscore";

    export default {
        name: "Featured",
        props: ['courseInfos', 'nodeIndex', 'chapterIndex', 'select_list', 'module_list'],
        data() {
            return {
                showResourseList: [],
                showPeitao: [],
                classId: 0,
                //选择的课程id
                select_course_id: this.$route.query.course_id,
                //精选课程
                featuredCourseList: [
                    {
                        class: 'courseware',
                        icon: '&#xe677;',
                        src: require('../../../assets/images/courseware.png'),
                        title: '课件',
                        index: 1,
                        mtype: 0,
                    },
                    {
                        class: 'textbook',
                        icon: '&#xe687;',
                        src: require('../../../assets/images/textbook.png'),
                        title: '教材',
                        index: 2,
                        mtype: 0,
                    },
                    {
                        class: 'micro-lesson',
                        icon: '&#xe688;',
                        src: require('../../../assets/images/micro-lesson.png'),
                        title: '微课',
                        index: 3,
                        mtype: 0,
                    },
                    {
                        class: 'animation',
                        icon: '&#xe68e;',
                        src: require('../../../assets/images/animation.png'),
                        title: '教学设计',
                        index: 4,
                        mtype: 0,
                    },
                    {
                        class: 'test-center',
                        icon: '&#xe678;',
                        src: require('../../../assets/images/test-center.png'),
                        title: '测试中心',
                        index: 6,
                        mtype: 0,
                    },
                    {
                        class: 'training-center',
                        icon: '&#xe66f;',
                        src: require('../../../assets/images/training-center.png'),
                        title: '实训中心',
                        index: 7,
                        mtype: 0,
                    },
                ],
                //应用列表
                appList: [],
                //资源类型
                adminResourcesTypeList: [],
                //是否显示查看应用详情弹窗
                dialogAppDetail: false,
                //应用信息
                appInfo: {
                    appTitle: '',
                    appContent: '',
                    home_link: '',
                    shop_link: '',
                    teacher_link: '',
                },
                newTypeList: [{
                    id: 1,
                    name: '知识点'
                }, {
                    id: 2,
                    name: "素材库"
                }, {
                    id: 3,
                    name: '案例库'
                }, {
                    id: 4,
                    name: '视频'
                }, {
                    id: 5,
                    name: '相关文章'
                }],
                newTypePic: [{
                    id: 1,
                    src: require('../../../assets/img/image/knowledge_point.png')
                }, {
                    id: 2,
                    src: require('../../../assets/img/image/sucai.png')
                }, {
                    id: 3,
                    src: require('../../../assets/img/image/anli.png')
                }, {
                    id: 4,
                    src: require('../../../assets/img/image/video.png')
                }, {
                    id: 5,
                    src: require('../../../assets/img/image/related_articles.png')
                }],
                resourceList: [],
                papersList: [],
                projectsList: [],
                courseContent: [],
                material_list: [],
                course_content: [],
                cKejian: [],
                cJiaocai: [],
                cWeike: [],
                cDonghua: [],
                cPeitao: [],
                cJiaoan: [],
                testCenter: [],
                practiceCenter: [],
                role: localStorage.getItem('role') ? Number(localStorage.getItem('role')) : 0,
            }
        },
        mounted() {
            this.getAllocation();
            this.courseInfo();
            if (this.role === 4) {
                this.featuredCourseList.splice(3, 1);
            }
        },
        watch: {
            nodeIndex(d) {
                this.courseInfo();
            },
            chapterIndex(d) {
                this.courseInfo();
            },
            $route() {
                this.select_course_id = this.$route.query.course_id
            }
        },
        methods: {
            getAllocation() {
                this.$shttp.axiosGet(this.$api.getAllocation, res => {
                    if (res.code = 200) {
                        if (res.data.appcenter_ids) {
                            let appcenter_ids = JSON.parse(res.data.appcenter_ids);
                            appcenter_ids.forEach(item => {
                                this.getAppList(item.school_app_id)
                            })
                        }
                    }
                })
            },
            getAppList(id) {
                this.$shttp.axiosGetBy(this.$api.app_showApp, {id: id ? id : 0}, res => {
                        if (res.code = 200) {
                            this.appList.push(res.data.data[0])
                        }
                        //应用排序，排列顺序跟总管理员应用中心一样
                        function compare(property){
                            return function(a,b){
                                let value1 = a[property];
                                let value2 = b[property];
                                return value2 - value1;
                            }
                        }
                        this.appList.sort(compare('parent_id'));
                    }
                )
            },
            courseInfo() {
                //
                Promise.all([
                    this.courseInfoByPromise(this.select_course_id),
                ]).then(([res]) => {
                    // console.log('课程内容', res);
                    this.courseName = res.course_name;
                    this.resourceList = res.reslist;
                    this.papersList = res.papers;
                    this.projectsList = res.projects;
                    this.courseContent = res.course_content[this.chapterIndex].children[this.nodeIndex].children;
                    // console.log('课程的平台资源', this.resourceList);
                    //整理资源
                    //课件
                    this.cKejian = this.formatResources(1);
                    // console.log('1222', this.cKejian);
                    //教材
                    this.cJiaocai = this.formatResources(2);
                    // console.log('2', this.cJiaocai);
                    //微课
                    this.cWeike = this.formatResources(3);
                    // console.log('3', this.cWeike);
                    //动画
                    this.cDonghua = this.formatResources(4);
                    // console.log('4', this.cDonghua);
                    //配套材料
                    this.cPeitao = this.formatResources(5);
                    this.handleShowPeiTao();
                    // console.log('5', this.cPeitao);
                    //教案
                    this.cJiaoan = this.formatResources(6);
                    // console.log('6', this.cJiaoan);
                    //测试中心
                    this.testCenter = this.formatExams(res.papers);
                    // console.log('小测', this.testCenter);
                    //实训中心
                    this.practiceCenter = this.formatProject(res.projects);
                    // console.log('实训', this.practiceCenter);
                }).catch(err => {
                    this.$common.axiosErrorMsg(err);
                });
            },
            courseInfoByPromise(id) {
                return new Promise((resolve, reject) => {
                    let param = {
                        id
                    }
                    this.$shttp.axiosGetBy(this.$api.courseinfo, param, (res) => {
                        if (res.code === 200) {
                            resolve(res.data);
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.msg,
                                duration: 1000
                            });
                            resolve(false);
                        }
                    }, (err) => {
                        reject(err);
                    })
                });
            },
            formatExams(exams) {
                let reslist = [];
                let reslist1 = [];
                _.each(exams, (item) => {
                    let tmp = {}
                    tmp = item;
                    tmp.title = item.paper_name;
                    tmp.ctype = 4;
                    tmp.oldIndex = 6;
                    reslist.push(tmp)
                });
                //遍历出当前章节的资源
                _.each(this.courseContent, (item) => {
                    _.each(reslist, (item1) => {
                        if (item.type === 4) {
                            if (Number(item.id) === Number(item1.id)) {
                                reslist1.push(item1);
                            }
                        }
                    })
                })
                return reslist1;
            },
            formatProject(projects) {
                let reslist = [];
                let reslist1 = [];
                _.each(projects, (item) => {
                    let tmp = {};
                    tmp = item;
                    tmp.id = item.id;
                    tmp.ctype = 5;
                    tmp.title = item.project_title;
                    tmp.oldIndex = 7;
                    reslist.push(tmp);
                });
                //遍历出当前章节的资源
                _.each(this.courseContent, (item) => {
                    _.each(reslist, (item1) => {
                        if (item.type === 5) {
                            if (Number(item.id) === Number(item1.id)) {
                                reslist1.push(item1);
                            }
                        }
                    })
                })
                return reslist1;
            },
            formatResources(type) {
                let reslist = [];
                let reslist1 = [];
                //格式化资源的属性名，这边是查出所有的资源
                _.each(this.resourceList, (item) => {
                    let tmp = {}
                    if (Number(item.resource_type_id) === Number(type)) {
                        tmp = item;
                        tmp.id = item.resource_id;         //资源id
                        tmp.title = item.resource_title;      // 资源名称
                        tmp.ctype = 3;                         //资源位于课程内容中的标识：系统资源 => 3; 系统测试(小测) => 4；项目（实训） => 5；配套材料（教师和学校管理员自己上传）=> 6
                        tmp.oldIndex = type === 6 ? item.material_type_id : type;
                        tmp.type = 3;
                        reslist.push(tmp);
                    }
                });
                //遍历出当前章节的资源
                _.each(this.courseContent, (item) => {
                    _.each(reslist, (item1) => {
                        if (Number(item.id) === Number(item1.id)) {
                            reslist1.push(item1);
                        }
                    })
                })
                return reslist1;
            },
            //获取资源分类列表
            getResourcesTypeList() {
                this.$http.axiosGet(this.$api.meterial_mtlTypeList, (res) => {
                    if (res.code === 200) {
                        this.adminResourcesTypeList = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            getResourceList(index) {
                this.showResourseList = [];
                switch (index) {
                    case 1:
                        this.showResourseList = this.cKejian;
                        break;
                    case 2:
                        this.showResourseList = this.cJiaocai;
                        break;
                    case 3:
                        this.showResourseList = this.cWeike;
                        break;
                    case 4:
                        this.showResourseList = this.cJiaoan;
                        break;
                    case 6:
                        this.showResourseList = this.testCenter;
                        break;
                    case 7:
                        this.showResourseList = this.practiceCenter;
                        break;
                }
            },
            //跳到课件列表页
            goToCourseWare(item) {
                this.$router.push({
                    path: '/qualityCourse/featureddetail',
                    query: {
                        course_id: this.select_course_id,
                        typeId: item.oldIndex,
                        zhangIndex: this.chapterIndex,
                        jieIndex: this.nodeIndex,
                        file_path: item.file_preview_path,
                        id: item.id
                    }
                });
            },
            //查看应用详情
            viewAppDetail(item) {
                this.dialogAppDetail = true;
                this.appInfo.appTitle = item.app_name;
                this.appInfo.appContent = item.intro;
                this.appInfo.home_link = item.home_link;
                this.appInfo.shop_link = item.shop_link;
                this.appInfo.teacher_link = item.teacher_link;
            },
            changeType(id) {
                this.classId = id;
                let arr = [];
                this.cPeitao.forEach(item => {
                    if (id === 0) {
                        arr.push(item)
                    } else {
                        if (item.material_type_id === id) {
                            arr.push(item)
                        }
                    }
                })
                this.showPeitao = arr;
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
            },
            handleShowPeiTao() {
                let arr = [];
                let len = this.cPeitao.length;
                if (len > 0 && len < 10) {
                    for (let i = 0; i < len; i++) {
                        arr.push(this.cPeitao[i]);
                    }
                } else if (len > 10) {
                    for (let j = 0; j < 10; j++) {
                        arr.push(this.cPeitao[j]);
                    }
                }
                this.showPeitao = arr;
            },
            handleCurrentChange(val) {
                let arr = [];
                let pageSize = 10;
                let num = (val - 1) * pageSize;
                let endNum = (val * pageSize) - 1;
                for (let i = num; i <= endNum; i++) {
                    arr.push(this.cPeitao[i]);
                }
                let newArr = arr.filter(item => {
                    return item != undefined
                })
                this.showPeitao = newArr
            },
        }
    }
</script>

<style scoped lang="scss">
    .temp-popover {
        display: flex;
        flex-direction: column;

        .popover-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            height: 30px;

            &:hover {
                background: #ECF5FF;

                span {
                    color: #409EFF;
                }
            }

            span {
                color: #666666;
                font-size: 14px;
                padding-left: 10px;
            }

            i {
                color: #666666;
                font-size: 12px;
                padding-right: 10px;
            }
        }
    }

    .featured-content {
        display: flex;
        justify-content: start;
        width: 100%;
        height: 100%;

        .course-index {
            display: flex;
            justify-content: start;
            width: 100%;
            height: 100%;
            background-image: url("../../../assets/img/image/course_index_bg.png");
            background-repeat: no-repeat;
            background-color: #fff;
            background-position: center;
            background-size: cover;

            .index-left {
                width: 500px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 40px;

                .course-name {
                    color: #0E0804;
                    font-size: 24px;
                }

                .course-detail {
                    font-size: 16px;
                    color: #444444;
                    line-height: 1.8;
                }

                .view-detail-btn {
                    width: 280px;
                    height: 36px;
                    background: #409EFF;
                    border-radius: 10px;
                    color: #fff;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    margin-top: 20px;

                    &:hover {
                        background: #66b1ff;
                        border-color: #66b1ff;
                        color: #FFF;
                        cursor: pointer;
                    }
                }
            }
        }

        .featured-index {
            display: flex;
            justify-content: start;
            width: 100%;
            height: 100%;

            .featured-left {
                display: flex;
                flex-direction: column;
                position: relative;
                flex: 1;
                width: 1%;
                background-color: #F2F8FF;
                margin-right: 20px;

                .featured-item {
                    &:nth-child(2) {
                        margin-bottom: 64px;
                    }

                    &:nth-child(3) {
                        margin-bottom: 30px;

                        .item-title {
                            display: flex;
                            justify-content: space-between;

                            .upload-material {
                                width: 70px;
                                height: 30px;
                                background: #ECF5FF;
                                border: 1px solid #DCECFE;
                                border-radius: 4px;
                                color: #409EFF;
                                font-size: 14px;
                                text-align: center;
                                line-height: 30px;

                                &:hover {
                                    color: #409EFF;
                                    border-color: #c6e2ff;
                                    background-color: #ecf5ff;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .item-title {
                        position: relative;
                        font-size: 16px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 4px;
                            width: 4px;
                            height: 16px;
                            background-color: #409EFF;
                        }

                        .text {
                            margin-left: 15px;
                        }
                    }

                    .featured-list {
                        width: 100%;
                        height: 120px;
                        background-color: #F5F7FA;
                        display: flex;
                        align-items: center;
                        box-sizing: border-box;


                        .featured-list-item {
                            display: flex;
                            margin-right: 30px;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: calc((100% - 100px) / 6);
                            /*min-width: 140px;*/
                            margin-right: 37px;
                            height: 120px;
                            background: #ffffff;
                            border-radius: 6px;

                            .inner-box {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }

                            &:hover {
                                box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                            }

                            .item-cover {
                                width: 50px;
                                height: 50px;
                                line-height: 40px;
                                text-align: center;
                                border-radius: 50%;
                                cursor: pointer;

                                i {
                                    font-size: 20px;
                                    color: #fff;
                                }

                                img {
                                    width: 50px;
                                    height: 50px;
                                }

                                .app-icon {
                                    max-width: 100%;
                                    max-height: 100%;
                                    border-radius: 5px;
                                }
                            }

                            &:nth-of-type(6) {
                                margin-right: 0;
                            }

                            .circle-box {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #ffffff;

                                ::v-deep .iconfont {
                                    font-size: 20px;
                                }
                            }

                            .item-name {
                                margin-top: 10px;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .module-content {
                    display: flex;
                    justify-content: space-between;
                    padding: 19px 20px;
                    /*position: absolute;*/
                    /*bottom: 20px;*/
                    /*left: 0;*/
                    /*right: 0;*/
                    align-items: center;
                    box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);

                    .module {
                        flex: 1;
                        width: 1%;

                        ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                            white-space: nowrap;
                        }
                    }

                    .module-list {
                        padding: 6px;
                        display: flex;

                        .module-item {
                            display: inline-block;
                            min-width: 60px;
                            min-height: 60px;
                            border: 1px dashed #CCCCCC;
                            border-radius: 10px;
                            margin-right: 15px;
                        }
                    }

                    .module-save-btn {
                        width: 60px;
                        height: 60px;
                        background: #ECF5FF;
                        border: 1px solid #DCECFE;
                        border-radius: 4px;
                        color: #409EFF;
                        font-size: 14px;
                        line-height: 60px;
                        text-align: center;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .featured-topbox {
                    z-index: 1;
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    padding: 30px 30px 30px 0;
                    position: relative;
                    background: #FFFFFF;
                    box-shadow: 0px 5px 10px 1px rgba(32, 32, 32, 0.05);

                    span {
                        display: inline-block;
                        line-height: 1;
                        font-size: 14px;
                        padding: 8px 12px;
                        border-radius: 4px;
                        font-weight: 400;
                        cursor: pointer;
                    }

                    .class-item {
                        background: #409EFF;
                        box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                        border-radius: 20px;
                        color: #ffffff;
                    }
                }

                .featured-top {
                    flex: 1;
                    height: 1%;
                    background: #ffffff;

                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                        margin-right: -37px !important;
                    }

                    ::v-deep .el-scrollbar__view {
                        margin-right: 20px;
                    }

                    .featured-mainbox {
                        width: 100%;

                        .mainbox-list {
                            padding: 30px 60px;
                            min-height: 520px;

                            .dragg-box {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                            }

                            .mainbox-list-item {
                                cursor: pointer;
                                width: 50%;
                                margin-bottom: 50px;
                                display: flex;

                                .item-title {
                                    margin-left: 30px;
                                    display: flex;
                                    align-items: center;
                                }
                            }

                            .no-data {
                                width: 100%;
                                font-size: 16px;
                                text-align: center;
                            }
                        }

                        .page-box {
                            text-align: center;
                        }
                    }
                }
            }

            .featured-right {
                width: 230px;
                min-width: 189px;
                background-color: #fff;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }

                .item-title {
                    position: relative;
                    font-size: 16px;
                    padding: 20px 20px 0;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 27px;
                        width: 6px;
                        height: 10px;
                        background: #409EFF;
                        border-radius: 3px;
                    }

                    .text {
                        margin-left: 15px;
                    }
                }

                .app-list {
                    padding: 0 20px;
                    display: flex;
                    flex-wrap: wrap;

                    .app-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 10px 16px 20px;
                        cursor: pointer;
                        border-radius: 10px;

                        &:hover {
                            transform: scale(1.2);
                            transition: 1s;
                            box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                            border-radius: 6px;
                        }

                        .app-cover {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 60px;
                            height: 60px;
                        }

                        img.app-icon {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 5px;
                        }

                        span.app-name {
                            color: #333;
                            font-size: 12px;
                            margin-top: 10px;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 160px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .app-detail-dialog {
        ::v-deep .el-dialog {
            ::v-deep .el-dialog__header {
                border-bottom: 1px solid #e6e7ea;
            }

            .title {
                text-align: center;
                margin: 0;
            }

            .content {
                display: flex;
                flex-direction: column;

                .h2 {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    margin: 10px 0;
                }

                .soft-introduction {
                    text-align: center;
                }

                .soft-content {
                    padding: 10px 50px;
                    min-height: 200px;
                }
            }

            ::v-deep .el-dialog__footer {
                border-top: 1px solid #e6e7ea;
                padding: 20px;

                a {
                    padding: 7px 20px;
                    font-size: 14px;
                    border-radius: 4px;
                    margin-right: 20px;
                }

                a.home {
                    border: 1px solid #DCDFE6;
                    color: #606266;

                    &:hover {
                        color: #409EFF;
                        border-color: #c6e2ff;
                        background-color: #ecf5ff;
                    }
                }

                a.shop {
                    color: #FFF;
                    background-color: #409EFF;
                    border-color: #409EFF;

                    &:hover {
                        background: #66b1ff;
                        border-color: #66b1ff;
                        color: #FFF;
                    }
                }
            }
        }
    }
</style>