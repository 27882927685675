<template>
    <div class="teacher-index-container">
        <div class="course-left">
            <el-select v-model="select_course" placeholder="请选择课程" clearable class="select-course"
                       @change="chooseCourse">
                <el-option v-for="item in teacherCourseList" :key="item.course_id" :label="item.course_name"
                           :value="item.course_id"></el-option>
            </el-select>
            <div class="item-title" :class="isChosen === true?'choseAll':''" @click="choseAll">
                <span class="text">课程总览</span>
            </div>
            <el-scrollbar class="zhang-jie" :native="false">
                <div class="zhang-jie-item" :class="setClass(zhangIndex)"
                     v-for="(zhangItem, zhangIndex) in courseZhangJie">
                    <div class="zhang"
                         :title="`第${zhangIndex+1}章：${zhangItem.name}（${zhangItem.children.length}节）`">
                        <span class="text">第{{zhangIndex+1}}章：{{zhangItem.name}}（{{zhangItem.children.length}}节）</span>
                    </div>
                    <div class="jie-box">
                        <div class="jie-inside-box">
                            <div class="jie"
                                 :class="{current: currentJie === jieIndex && chapterIndex == zhangIndex}"
                                 v-for="(jieItem, jieIndex) in zhangItem.children"
                                 @click="clickJie(zhangIndex,jieIndex)"
                                 :title="`0${jieIndex+1} | ${jieItem.name}`">
                                0{{jieIndex+1}} | {{jieItem.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="course-right">
            <div class="container">
                <router-view v-if="activeName === 'teacherFeatured' || 'teacherCourseIndex'"
                             :courseInfos="courseInfos" :nodeIndex="nodeIndex" :chapterIndex="chapterIndex"
                             @handleList="handleList" :select_list="select_list" :module_list="module_list"
                             @handleSave="handleSave" @savaData="savaData" @savaId="savaId" :searchInput="searchInput"
                             :courseTempList="courseTempList" @updateTempList="updateTempList"/>
                <router-view v-else/>
            </div>
        </div>
    </div>
</template>

<script>
    import teacherIndex from 'components/teacherCourse/teacherIndex.vue'
    import teacherFeatured from 'components/teacherCourse/teacherFeatured.vue'
    import teacherCourseWare from 'components/teacherCourse/teacherCourseWare.vue'
    import teacherTextbook from 'components/teacherCourse/teacherTextbook.vue'
    import teacherMicroLesson from 'components/teacherCourse/teacherMicroLesson.vue'
    import teacherAnimation from 'components/teacherCourse/teacherAnimation.vue'
    import _ from "underscore";
    import {mapActions} from "vuex";

    export default {
        name: "TeacherCourseLayout",
        components: {
            teacherIndex,
            teacherFeatured,
            teacherCourseWare,
            teacherTextbook,
            teacherMicroLesson,
            teacherAnimation
        },
        data() {
            return {
                isChosen: true,
                activeName: 'teacherCourseIndex',
                searchInput: '',
                //选择的课程
                select_course: '',
                teacherCourseList: [],
                //章节
                courseZhangJie: [],
                currentJie: '',
                //总管理员分配的章节
                allocatedCourse: [],
                allocatedCourseNum: null,
                //课程介绍
                courseInfos: {},
                zhang_arr: [],
                nodeIndex: 0,
                chapterIndex: 0,
                select_list: [],
                select_list1: [],
                module_list: [],
                dataObj: {},
                id: '',
                courseTempList: [],
            }
        },
        created() {
            this.activeName = this.$route.meta.tabName;
            this.getTeacherCourseList();
            this.module_list = new Array(14).fill({})
            this.select_list = this.module_list;
            if (this.activeName === 'teacherFeatured' && this.isChosen == true) {
                this.isChosen = false;
                this.chapterIndex = localStorage.getItem('teacherChapter');
                this.nodeIndex = localStorage.getItem('teacherNode');
                this.currentJie = Number(this.nodeIndex);
            }

          this.setKey()
        },
        watch: {
            $route(route) {
                if (route.name === 'teacherCourseIndex') {
                    this.activeName = 'teacherCourseIndex';
                    this.isChosen = true;
                    this.currentJie = '';
                } else if (route.name === 'teacherFeatured') {
                    this.activeName = 'teacherFeatured';
                    this.isChosen = false;
                    // this.currentJie = 0;
                }
              this.setKey()
            }
        },
        methods: {
            ...mapActions([
                'setCourseAttrC'
            ]),

          setKey() {
            if (this.$route.name === 'teacherCourseWareDetail') {
              document.oncontextmenu = function () {
                return false
              }
              document.onkeydown = function () {
                if(window.event && window.event.keyCode === 123) {
                  // alert("F12被禁用");
                  // event.keyCode = 0;
                  event.returnValue = false;
                }
                if(window.event && window.event.keyCode === 13) {
                  // alert("3333");
                  window.event.keyCode = 505;
                }
                if(window.event && window.event.keyCode === 8) {
                  // alert(str+"\n请使用Del键进行字符的删除操作！");
                  window.event.returnValue = false;
                }
              }
            } else {
              document.oncontextmenu = function () {
                return true
              }
              document.onkeydown = function () {
                if(window.event && window.event.keyCode === 123) {
                  event.returnValue = true;
                }
                if(window.event && window.event.keyCode === 13) {
                  window.event.keyCode = 505;
                }
                if(window.event && window.event.keyCode === 8) {
                  window.event.returnValue = true;
                }
              }
            }
          },
            setClass(index) {
                if (this.zhang_arr.indexOf(index) !== -1) {
                    return 'isAllocatedClass';
                } else {
                    return '';
                }

            },
            choseAll() {
                this.isChosen = true;
                this.chapterIndex = '';
                this.nodeIndex = '';
                this.currentJie = '';
                this.$router.push({
                    path: '/qualityCourse/teacher/index',
                    query: {
                        course_id: this.select_course
                    }
                })
            },
            //选择课程
            chooseCourse(val) {
                this.currentJie = 0;
                this.chapterIndex = 0;
                this.nodeIndex = 0;
                this.module_list = new Array(14).fill({})
                this.select_list = this.module_list;
                this.searchInput = '';
                this.$router.push({
                    path: '/qualityCourse/teacher/index',
                    query: {
                        course_id: val,
                    },
                });
                this.getCourseInfo(val);
                this.getTeacherCourseList();
            },
            getTeacherCourseList() {
                this.$thttp.axiosGet(this.$api.showTeaCourse, (res) => {
                    if (res.code === 200) {
                        this.teacherCourseList = res.data.course_list;
                        if (this.$route.query.course_id) {
                            this.select_course = Number(this.$route.query.course_id);
                        } else {
                            this.select_course = res.data.course_list[0].course_id;
                            this.$router.push({
                                query: {
                                    course_id: this.select_course,
                                },
                            });
                        }
                        if (this.select_course) {
                            this.getCourseInfo(this.select_course);
                            this.getCourseTempList()
                        }
                        this.allocatedCourse = JSON.parse(res.data.course_data);
                        let zhang_arr = [];
                        _.each(this.allocatedCourse, (_item) => {
                            if (Number(_item.course_id) === Number(this.select_course)) {
                                zhang_arr.push(_item.zhang_arr);
                            }
                        });
                        this.zhang_arr = zhang_arr
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            //获取课程详情
            getCourseInfo(courseId) {
                let params = {
                    id: courseId
                };
                this.$thttp.axiosGetBy(this.$api.courseinfo, params, (res) => {
                    if (res.code === 200) {
                        this.courseZhangJie = res.data.course_content;
                        this.courseInfos = res.data;
                    }
                }, (error) => {
                    console.log(error);
                })
            },
            clickJie(index, jIndex) {
                this.searchInput = '';
                if (this.zhang_arr.indexOf(index) !== -1) {
                    this.isChosen = false;
                    this.chapterIndex = index;
                    this.nodeIndex = jIndex;
                    localStorage.setItem('teacherChapter', index);
                    localStorage.setItem('teacherNode', jIndex);
                    this.getCourseTempList()
                    this.$router.push({
                        path: '/qualityCourse/teacher/teacherFeatured',
                        query: {
                            course_id: this.select_course,
                        }
                    });
                    this.currentJie = jIndex;
                } else {
                    this.$message({
                        type: 'warning',
                        message: '该节内容未授权',
                        duration: 1000
                    });
                }
            },
            handleClick(tab, event) {
                this.searchInput = '';
                this.$router.push({
                    name: tab.name,
                    query: {
                        course_id: this.select_course
                    },
                })
            },
            handleList(val) {
                this.select_list = val;
                this.module_list = this.select_list;
            },
            savaData(val) {
                this.dataObj = val;
            },
            savaId(val) {
                this.id = val;
            },
            formatData(dataList) {
                let obj = {}
                dataList.forEach(item => {
                    if (JSON.stringify(item) !== "{}") {
                        let newIndex = dataList.indexOf(item)
                        //处理精选课程
                        if (item.mtype == 0) {
                            switch (item.index) {
                                case 1:
                                    obj = this.dataObj.cKejian[0];
                                    break;
                                case 2:
                                    obj = this.dataObj.cJiaocai[0];
                                    break;
                                case 3:
                                    obj = this.dataObj.cWeike[0];
                                    break;
                                case 4:
                                    obj = this.dataObj.cDonghua[0];
                                    break;
                                case 5:
                                    obj = this.dataObj.cPeitao[0];
                                    break;
                                case 6:
                                    obj = this.dataObj.testCenter[0];
                                    break;
                                case 7:
                                    obj = this.dataObj.practiceCenter[0];
                                    break;
                            }
                            let newObj = Object.assign(dataList[newIndex], obj);
                            dataList[newIndex] = newObj;
                        } else if (item.mtype == 1) {//处理配套材料
                            switch (item.id) {
                                case 1:
                                    obj = this.dataObj.cZhishidian[0];
                                    break;
                                case 2:
                                    obj = this.dataObj.cSucaiku[0];
                                    break;
                                case 3:
                                    obj = this.dataObj.cAnliku[0];
                                    break;
                                case 4:
                                    obj = this.dataObj.cPeiWeike[0];
                                    break;
                                case 5:
                                    obj = this.dataObj.cXiangguanwenzhang[0];
                                    break;
                                case 6:
                                    obj = this.dataObj.cPersonResource[0];
                                    break;
                            }
                            let newObj = Object.assign(dataList[newIndex], obj);
                            dataList[newIndex] = newObj;
                        }
                    }
                })
            },
            handleSave(val) {
                let dataList = JSON.parse(JSON.stringify(this.select_list));
                this.formatData(dataList)
                let arr = [];
                dataList.forEach((item, index) => {
                    if (JSON.stringify(item) !== "{}") {
                        let obj = {
                            type: item.ctype,
                            id: item.id,
                            index: index,
                        }
                        if (item.icon) {
                            obj.oldIndex = item.oldIndex;
                        }
                        arr.push(obj)
                    }
                })
                let param = {
                    course_id: this.select_course,
                    course_chapter: this.chapterIndex,
                    course_node: this.nodeIndex,
                    course_content: JSON.stringify(arr)
                }
                if (this.id) {
                    param.id = this.id;
                }
                if (val) {
                    this.$thttp.axiosPost(this.$api.saveCourseCustom, param, res => {
                        if (res.code == 200) {
                            this.$message.success(res.msg)
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            },
            //获取选择模板列表
            getCourseTempList() {
                let param = {
                    course_id: this.select_course,
                    course_chapter: this.chapterIndex,
                    course_node: this.nodeIndex
                }
                this.$thttp.axiosGetBy(this.$api.course_getCourseTemp, param, res => {
                    if (res.code === 200) {
                        this.courseTempList = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            updateTempList() {
                this.getCourseTempList()
            },
        }
    }
</script>

<style scoped lang="scss">
    /*::v-deep.el-tabs__nav-scroll{*/
    /*    display: flex;*/
    /*    justify-content: center!important;*/
    /*}*/
    .teacher-index-container {
        display: flex;
        justify-content: start;
        position: relative;
        height: 100%;
        padding: 20px 70px;
        box-sizing: border-box;
        background: #F2F8FF;
        overflow: auto;

        .course-left {
            display: flex;
            flex-direction: column;
            width: 300px;
            min-width: 300px;
            height: 100%;
            background-color: #fff;
            margin-right: 20px;

            .select-course {
                width: 260px;
                height: 55px;
                background: #F5F7FA;
                border: 1px solid #DDDDDD;
                border-radius: 6px;
                margin: 20px;

                ::v-deep .el-input__inner {
                    color: #333;
                    border: transparent;
                    background-color: #F5F7FA;
                    height: 55px;
                }
            }

            .item-title {
                position: relative;
                font-size: 15px;
                margin: 0 20px;
                height: 40px;
                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    position: absolute;
                    top: 17px;
                    width: 6px;
                    height: 10px;
                    background: #409EFF;
                    border-radius: 3px;
                }

                .text {
                    margin-left: 15px;
                    font-size: 16px;
                }

                &.choseAll {
                    background: #409EFF;
                    border-radius: 20px;
                    cursor: pointer;
                    color: #ffffff;

                    &:before {
                        content: none;
                    }
                }
            }

            .zhang-jie {
                flex: 1;
                height: 1%;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }

                .zhang-jie-item {
                    padding: 0 20px 20px;
                    color: #999;

                    .zhang {
                        height: 30px;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 9px;
                            width: 6px;
                            height: 10px;
                            background: #409EFF;
                            border-radius: 3px;
                        }

                        .text {
                            margin-left: 15px;
                            font-size: 16px;
                        }

                    }

                    .jie-box {
                        background: #F5F7FA;

                        .jie-inside-box {
                            padding: 8px 0;
                        }
                    }

                    .jie {
                        cursor: pointer;
                        height: 40px;
                        line-height: 30px;
                        /*color: #409EFF;*/
                        padding-left: 40px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                    }

                    .current {
                        background: #409EFF;
                        border-radius: 20px;
                        color: #ffffff;
                    }

                    &.isAllocatedClass {
                        color: #333;

                        .jie {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .course-right {
            display: flex;
            flex-direction: column;
            position: relative;
            flex: 1;
            width: 1%;

            .container {
                flex: 1;
                height: 1%;
            }

            .index-tabs {
                width: 100%;
                height: auto;
                background: transparent;
                padding: 0;

                ::v-deep .el-tabs__header {
                    background-color: #fff;
                    padding: 0 20px;

                    ::v-deep .el-tabs__active-bar {
                        display: none;
                    }

                    .el-tabs__nav {
                        width: calc(100% - 209px);
                        display: flex;
                        justify-content: center;

                        .el-tabs__item {
                            height: 60px;
                            line-height: 60px;

                            &.is-active {
                                &:after {
                                    height: 2px;
                                    width: calc(100% - 40px);
                                    background-color: #409EFF;
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 20px;
                                }

                                &:last-child:after {
                                    width: calc(100% - 20px);
                                }

                                &:nth-child(2):after {
                                    width: calc(100% - 20px);
                                    left: 0;
                                }
                            }
                        }
                    }

                    .el-tabs__nav-wrap::after {
                        background-color: transparent;
                    }
                }

                ::v-deep .el-tabs__content {
                    background: #f4f5f5;
                }
            }

            .search-input {
                position: absolute;
                top: 15px;
                right: 20px;
                width: 270px;

                ::v-deep .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
</style>