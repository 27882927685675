<template>
    <div class="admin-container">
        <HeaderModule></HeaderModule>
        <router-view/>
    </div>
</template>

<script>
    import HeaderModule from 'components/adminHeader/HeaderModule';

    export default {
        name: "Layout",
        components: {
            HeaderModule
        }
    }
</script>

<style lang="scss" scoped>
.admin-container {
    height: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
}
</style>
