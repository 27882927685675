<template>
    <div class="animation-content" :native="false">
        <el-scrollbar class="animation-list">
            <div class="animation">
                <div class="animation-item" v-for="item in animationList" v-if="animationList.length > 0" @click="viewDetail(item)">
                    <div class="animation-cover">
                        <img src="../../../assets/img/image/animation.png" alt="" class="animation-icon">
                    </div>
                    <span class="animation-name" :title="item.resource_title">{{item.resource_title}}</span>
                </div>
                <div v-if="animationList.length === 0" class="no-data">
                    <span>暂无数据</span>
                </div>
            </div>
        </el-scrollbar>
        <ResourceDialog :dialogVisible="dialogVisible" :src="src" @closeDialog="closeDialog"></ResourceDialog>
    </div>
</template>

<script>
    import ResourceDialog from "../../components/ResourceDialog";

    export default {
        name: "Animation",
        components: {
            ResourceDialog
        },
        props: ['searchInput'],
        data() {
            return {
                animationList: [],
                src: "",
                dialogVisible: false,
            }
        },
        created() {
            this.getCourseInfo();
        },
        watch: {
            searchInput(d) {
                if(d !== ""){
                    let arr = [];
                    for (let i = 0; i < this.animationList.length; i++) {
                        if (this.animationList[i].resource_title.indexOf(d) !== -1) {
                            arr.push(this.animationList[i]);
                        }
                    }
                    this.animationList = arr;
                } else {
                    this.animationList = [];
                    this.getCourseInfo();
                }
            }
        },
        methods: {
            //获取动画列表
            getCourseInfo() {
                let params = {
                    id: this.$route.query.course_id
                };
                this.$shttp.axiosGetBy(this.$api.courseinfo, params, (res) => {
                    if (res.code === 200) {
                        // console.log('动画',res.data.reslist)
                        for (let i = 0; i < res.data.reslist.length; i++) {
                            if (res.data.reslist[i].resource_type_id === 4) {
                                this.animationList.push(res.data.reslist[i]);
                            }
                        }
                    }
                }, (error) => {
                    console.log(error);
                })
            },
            viewDetail(item) {
                this.src = item.file_preview_path;
                this.dialogVisible = true;
            },
            closeDialog() {
                this.src = '';
                this.dialogVisible = false
            },
        }
    }
</script>

<style scoped lang="scss">
    .animation-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .animation-list {
            flex: 1;
            width: 100%;
            height: 1%;
            background-color: #fff;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .animation {
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;
                .animation-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 30px 16px 0;
                    cursor: pointer;
                    border-radius: 10px;
                    &:hover {
                        transform: scale(1.2);
                        transition: 1s;
                    }
                    .animation-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 107px;
                        height: 107px;
                    }
                    img.animation-icon {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 5px;
                    }
                    span.animation-name {
                        color: #333;
                        font-size: 12px;
                        margin-top: 10px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 107px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
</style>