const ws = {
    type: 2, //swoole: 1 端口号：9501；workerman: 2 端口号：8282
    ip: localStorage.getItem('ip') ? localStorage.getItem('ip') : '47.97.113.174',
    // ip: '116.198.161.21',
    port: 9501,
    url: '',
    baseUrl: 'http://yike.e-class.me/'
}
ws.port = (ws.type === 1) ? '9501' : '8282';
if (process.env.NODE_ENV === "development") {
    // ws.url = `ws://127.0.0.1:2346`
    ws.url = `ws://yun.yikeos.com/wss`
    console.log(location.protocol)
} else {
    console.log("pro", location.protocol)
    if (location.protocol === "https:") {
        ws.url = `wss://${location.host}/wss`
    } else {
        ws.url = `ws://${location.host}/wss`
    }

}


export default ws;
