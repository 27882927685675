<template>
    <div class="course-container">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: "AdminCourseLayout",
    }
</script>

<style lang="scss" scoped>
.course-container {
    height: 100%;
    width: 100%;
}
</style>
