<template>
    <div class="featured-content">
        <!--课程首页-->
        <el-scrollbar class="course-index">
            <div class="top-box">
                <div class="top-leftBox">
                    <img v-if="courseInfos.course_cover !== null" :src="courseInfos.course_cover" alt="">
                    <img v-else src="../../assets/img/image/s_course_bg.png" alt="">
                </div>
                <div class="top-rightBox">
                    <div class="rightBox-course">
                        <div class="course-title-box1">{{courseInfos.course_name}}</div>
                        <div class="course-title-box2" v-if="courseInfos.course_type === 1">
                            <i class="iconfont">&#xe60d;</i>精品课程
                        </div>
                    </div>
                    <div class="rightBox-course">
                        <div class="course-title-midBox">
                            课程分类：<span>{{courseInfos.rc_name}}</span>
                        </div>
                    </div>
                    <div class="rightBox-course">
                        <div class="course-title-midBox">
                            课程简介：
                            <span>{{courseInfos.course_desc}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-box">
                <div class="course-list">
                    <div v-if="course_video.length > 0" class="list-item" @click="viewFile(course_video[0])">
                        <img src="../../assets/course/left1.png" alt="">
                        <span>课程视频导读</span>
                    </div>
                    <div class="list-item" @click="viewCourseDetail">
                        <img src="../../assets/course/course-detail.png" alt="">
                        <span>课程详情介绍</span>
                    </div>
                    <div v-if="courseInfo.fishbone_map.length && courseInfo.fishbone_map.length > 0" class="list-item"
                         @click="viewFishBone">
                        <img src="../../assets/course/fish-bone.png" alt="">
                        <span>鱼骨图</span>
                    </div>
                    <div v-if="courseInfo.knowledge_map.length && courseInfo.knowledge_map.length > 0" class="list-item"
                         @click="viewKnowledge">
                        <img src="../../assets/course/knowledge-domain.png" alt="">
                        <span>知识图谱</span>
                    </div>
                    <div class="list-item" @click="viewFile(item)"
                         v-for="(item,index) in courseInfo.files_data"
                         :class="item.class">
                        <img :class="'img'+index" :src="item.src" alt="">
                        <span>{{item.name}}</span>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <el-dialog
                title="知识图谱"
                :visible.sync="knowledgeVisible"
                :before-close="handleCloseKnow">
            <KnowledgeDialog :knowledgeData="courseInfo.knowledge_map" v-if="knowledgeVisible"
                             :knowledgeVisible="knowledgeVisible"
                             @showKnowledge="showKnowledge"></KnowledgeDialog>
        </el-dialog>
        <FishDialog :fishData="courseInfo.fishbone_map" :fishVisible="fishVisible" v-if="fishVisible"
                    @showFish="showFish"></FishDialog>
    </div>
</template>

<script>
    import FishDialog from "./component/FishDialog";
    import KnowledgeDialog from "./component/KnowledgeDialog";

    export default {
        name: "teacherCourseIndex",
        props: ['courseInfos'],
        components: {
            FishDialog,
            KnowledgeDialog,
        },
        data() {
            return {
                courseInfo: {
                    course_id: "",//课程id
                    contnet_description: "",//内容介绍
                    jx_standard: "",//教学标准
                    resource_type: "",//资源类型
                    wk_introductory: "",//微课简介
                    sx_introductory: "",//实训简介
                    correlation_course: [],//相关课程
                    fishbone_map: [],//鱼骨图json
                    knowledge_map: [],//知识图谱json
                    files_data: [],//文件json
                },
                imgArr: [{
                    src: require('../../assets/course/course-standard.png')
                }, {
                    src: require('../../assets/course/evaluation-scheme.png')
                }, {
                    src: require('../../assets/course/course-outline.png')
                }, {
                    src: require('../../assets/course/course-teach.png')
                }, {
                    src: require('../../assets/course/course-check.png')
                }, {
                    src: require('../../assets/course/course-calendar.png')
                }, {
                    src: require('../../assets/course/feedback-manual.png')
                }, {
                    src: require('../../assets/course/feedback-form.png')
                }, {
                    src: require('../../assets/course/lab-report.png')
                }, {
                    src: require('../../assets/course/evaluation.png')
                }, {
                    src: require('../../assets/course/left1.png')
                }, {
                    src: require('../../assets/course/left2.png')
                }, {
                    src: require('../../assets/course/left3.png')
                },],
                fishVisible: false,
                knowledgeVisible: false,
                course_video: [],
            }
        },
        watch: {
            courseInfos(d) {
                this.courseInfo = {
                    course_id: "",//课程id
                    contnet_description: "",//内容介绍
                    jx_standard: "",//教学标准
                    resource_type: "",//资源类型
                    wk_introductory: "",//微课简介
                    sx_introductory: "",//实训简介
                    correlation_course: [],//相关课程
                    fishbone_map: [],//鱼骨图json
                    knowledge_map: [],//知识图谱json
                    files_data: [],//文件json
                },
                    this.course_video = [],
                    this.getCourseContent();

            }
        },
        mounted() {
            if(this.courseInfos.course_id !== undefined){
                this.getCourseContent();
            }
        },
        methods: {
            getCourseContent() {
                let param = {
                    course_id: this.courseInfos.course_id
                }
                this.$thttp.axiosGetBy(this.$api.contents_showCourseContent, param, res => {
                    if (res.code == 200) {
                        this.courseInfo.contnet_description = res.data.contnet_description;
                        this.courseInfo.jx_standard = res.data.jx_standard;
                        this.courseInfo.resource_type = res.data.resource_type;
                        this.courseInfo.wk_introductory = res.data.wk_introductory;
                        this.courseInfo.sx_introductory = res.data.sx_introductory;
                        this.courseInfo.correlation_course = res.data.correlation_course.split(',').map(Number);
                        this.courseInfo.fishbone_map = JSON.parse(res.data.fishbone_map);
                        this.courseInfo.knowledge_map = JSON.parse(res.data.knowledge_map);
                        this.courseInfo.files_data = res.data.files_data;
                        this.formatFile();
                    } else {
                        // this.$message.warning(res.msg)
                    }
                })
            },
            viewFile(item) {
                let url = item.file_path.url;
                window.open(url, '_blank')
            },
            formatFile() {
                let arr = []
                //去除没有文件的
                this.courseInfo.files_data.forEach((item, index) => {
                    item.id = index + 1;
                    if (item.file_name) {
                        arr.push(item)
                    }
                })
                this.courseInfo.files_data = arr;
                this.formatShowData();
                let countLen = 0;
                if (this.courseInfo.fishbone_map.length > 0) {
                    countLen += 1;
                }
                if (this.courseInfo.knowledge_map.length > 0) {
                    countLen += 1;
                }
                let len = this.courseInfo.files_data.length
                switch (countLen) {
                    case 0:
                        this.courseInfo.files_data.forEach((item, index) => {
                            if (index < 2) {
                                item.class = 'row1'
                            }
                            if (len > 10) {
                                if (index > 1) {
                                    item.class = 'row4'
                                }
                            } else {
                                if (index > 1) {
                                    item.class = 'row3'
                                }
                            }
                        })
                        break;
                    case 1:
                        this.courseInfo.files_data.forEach((item, index) => {
                            if (index < 1) {
                                item.class = 'row1'
                            }
                            if (len > 9) {
                                if (index > 0) {
                                    item.class = 'row4'
                                }
                            } else {
                                if (index > 0) {
                                    item.class = 'row3'
                                }
                            }
                        })
                        break;
                    case 2:
                        this.courseInfo.files_data.forEach((item, index) => {
                            if (len > 8) {
                                item.class = 'row4'
                            } else {
                                item.class = 'row3'
                            }
                        })
                        break;
                }
            },
            formatShowData() {
                if (this.courseInfo.files_data.length > 0 && this.courseInfo.files_data[0].id === 1) {
                    this.course_video.push(this.courseInfo.files_data[0]);
                    this.courseInfo.files_data.splice(0, 1)
                }
                this.courseInfo.files_data.forEach((item, index) => {
                    switch (item.id) {
                        case 2:
                            item.src = require('../../assets/course/fish-bone.png');
                            break;
                        case 3:
                            item.src = require('../../assets/course/knowledge-domain.png');
                            break;
                        case 4:
                            item.src = require('../../assets/course/course-teach.png');
                            break;
                        case 5:
                            item.src = require('../../assets/course/course-calendar.png');
                            break;
                        case 6:
                            item.src = require('../../assets/course/left2.png');
                            break;
                        case 7:
                            item.src = require('../../assets/course/course-check.png');
                            break;
                        case 8:
                            item.src = require('../../assets/course/course-outline.png');
                            break;
                        case 9:
                            item.src = require('../../assets/course/evaluation-scheme.png');
                            break;
                        case 10:
                            item.src = require('../../assets/course/course-standard.png');
                            break;
                        case 11:
                            item.src = require('../../assets/course/left3.png');
                            break;
                        case 12:
                            item.src = require('../../assets/course/evaluation.png');
                            break;
                        case 13:
                            item.src = require('../../assets/course/feedback-manual.png');
                            break;
                        case 14:
                            item.src = require('../../assets/course/feedback-form.png');
                            break;
                        case 15:
                            item.src = require('../../assets/course/left3.png');
                            break;
                    }
                })
            },
            viewCourseDetail() {
                this.$thttp.axiosGetBy(this.$api.contents_showCourseContent, {course_id: this.$route.query.course_id}, (res) => {
                    if (res.code === 200) {
                        if (res.data.contnet_description === '' &&
                            res.data.jx_standard === '' &&
                            res.data.resource_type === '' &&
                            res.data.wk_introductory === '' &&
                            res.data.sx_introductory === '' &&
                            res.data.correlation_course_data.length === 0) {
                            return this.$message.warning('FAIL!该课程暂无首页,请联系管理员创建!')
                        }
                        this.$router.push({
                            name: 'teacherCourseDetail',
                            query: {
                                course_id: this.$route.query.course_id
                            }
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            viewFishBone() {
                if (this.courseInfo.fishbone_map.length == 0) {
                    this.$message.warning('暂无数据');
                    return
                }
                this.fishVisible = true;
            },
            viewKnowledge() {
                if (this.courseInfo.knowledge_map.length == 0) {
                    this.$message.warning('暂无数据');
                    return
                }
                this.knowledgeVisible = true;
            },
            showFish(val) {
                this.fishVisible = val;
            },
            showKnowledge(val) {
                this.knowledgeVisible = val;
            },
            handleCloseKnow() {
                this.knowledgeVisible = false;
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-dialog {
        width: calc(100% - 120px);
        min-width: 1000px;
    }

    ::v-deep .el-dialog__header::after {
        content: '';
        width: 100%;
        height: 10px;
        display: block;
        margin: 0 auto;
        border-bottom: 1px solid #EEEEEE;
    }

    ::v-deep .el-dialog__footer {
        text-align: center;
    }

    ::v-deep .el-dialog__body {
        position: relative;
    }

    .featured-content {
        display: flex;
        justify-content: start;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        ::v-deep .el-dialog {
            margin-top: 10vh !important;
        }

        .course-index {
            width: 100%;
            height: 100%;
            /*min-width: 1460px;*/
            background-size: 100% 100%;
            background: linear-gradient(184deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0));
            box-shadow: 0px 9px 9px 1px rgba(9, 95, 185, 0.1);
            display: flex;
            flex-direction: column;
            flex: 1;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .top-box {
                width: 100%;
                height: 50%;
                min-height: 440px;
                background: linear-gradient(153deg, #ECF6FF, #F0F3FF);
                display: flex;
                /*flex: 1;*/

                .top-leftBox {
                    width: 586px;
                    height: 330px;
                    padding: 60px 30px 32px 60px;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                    }
                }

                .top-rightBox {
                    height: 330px;
                    padding: 60px 60px 32px 0;

                    .rightBox-course {
                        display: flex;
                        margin-top: 30px;

                        .course-title-box1 {
                            font-size: 24px;
                            font-weight: 400;
                            color: #313F54;
                        }

                        .course-title-box2 {
                            width: 110px;
                            background-image: url("../../assets/images/courseIndex.png");
                            margin-left: 30px;
                            text-align: center;
                            color: #FFFFFF;
                            height: 30px;

                            i {
                                margin-right: 8px;
                                font-size: 14px;
                            }
                        }

                        .course-title-midBox {
                            font-size: 16px;
                            color: #313F54;
                            max-width: 700px;
                            max-height: 150px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 7;

                            span {
                                font-size: 14px;
                                word-break: break-all;
                                line-height: 1.5;
                                max-height: 120px;
                            }
                        }
                    }
                }
            }

            .bottom-box {
                width: 100%;
                height: 50%;
                flex: 1;
                min-height: 440px;
                background: #F2F8FF;

                .course-list {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 29px 65px 65px;

                    .list-item {
                        cursor: pointer;
                        width: calc(25% - 30px);
                        height: 100px;
                        background: #FFFFFF;
                        box-shadow: 0px 5px 17px 3px rgba(9, 95, 185, 0.1);
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        margin-right: 28px;

                        &:nth-of-type(4n) {
                            margin-right: 0;
                        }

                        &:nth-of-type(4) {
                            margin-top: 0 !important;
                        }

                        img {
                            width: 58px;
                            height: 58px;
                            margin-left: 40px;
                        }

                        span {
                            margin-left: 20px;
                        }

                        &.row3 {
                            margin-top: 80px;

                        }

                        &.row4 {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
</style>