<template>
    <div class="header-wrapper">
        <div class="header">
            <div class="fixed-header">
                <router-link to="/" class="header-logo">
                     <img class="ima-logo" src="../../assets/images/jd_course_logo.png" alt="" />
<!--                    <img src="../../assets/images/yun.png" alt="" />-->
                </router-link>
                <el-menu :default-active="activeIndex" class="nav" mode="horizontal">
                    <!--<el-menu-item index="qualityCourseIntegrationCourse">-->
                        <!--<router-link class="nav-item" to="/qualityCourse/integrationCourse">一体化课程</router-link>-->
                    <!--</el-menu-item>-->
                    <!--<el-menu-item index="training">-->
                        <!--<a :href="softUrl" target="_blank" class="nav-item">实训系统</a>-->
                    <!--</el-menu-item>-->
                    <el-menu-item index="qualityCourseHomePublicClass">
                        <router-link class="nav-item" to="/qualityCourse/home/PublicClass">公开课</router-link>
                    </el-menu-item>
                    <el-menu-item index="qualityCourseContactUs">
                        <router-link class="nav-item" to="/qualityCourse/contactUs">联系我们</router-link>
                    </el-menu-item>
                    <el-menu-item index="qualityCourseNewsCenter">
                        <router-link class="nav-item" to="/qualityCourse/newsCenter">新闻中心</router-link>
                    </el-menu-item>
                </el-menu>
                <el-button v-if="isEnter" class="home-login-btn" type="danger" style="width: 70px;height: 40px;" @click="enterCourse">进入</el-button>
                <el-dropdown @command="handleLogin" v-if="isLogin">
                    <el-button class="home-login-btn" type="danger">登录</el-button>
                    <el-dropdown-menu slot="dropdown" class="home-btns">
                        <el-dropdown-item command="t">教师登录</el-dropdown-item>
                        <el-dropdown-item command="s">学生登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isLogin: true,
                isEnter: false,
                activeIndex: '',
                activeHomeIndex: ['qualityCourseIntegrationCourse', 'qualityCourseHomePublicClass', 'qualityCourseHomePublicClassDetail', 'qualityCourseContactUs', 'qualityCourseNewsCenter'],
                softUrl: localStorage.getItem('softUrl') ? localStorage.getItem('softUrl') : 'http://shixun.jdekt.com/',
                role: Number(localStorage.getItem('role')) || '',
            }
        },
        created() {
            //检测登录状态是否失效
            this.$shttp.axiosGet(this.$api.checkToken, (res) => {
                if (res.code === 400
                        || ((Number(localStorage.getItem('role')) === 1 || Number(localStorage.getItem('role')) === 2) && res.code === 200)) {
                    if (localStorage.getItem('sToken')) {
                        this.$message({
                            type: 'warning',
                            duration: 1500,
                            message: '用户登录失效，请重新登录',
                            onClose: () => {
                                localStorage.clear();
                                window.location.reload();
                            }
                        });
                    }
                }
            }, (err) => {
                console.log(err);
            });
            this.activeIndex = this.$route.name;
            if (localStorage.getItem('sUsername')) {
                this.isLogin = false;
                this.isEnter = true;
            } else {
                this.isLogin = true;
                this.isEnter = false;
            }

        },
        watch: {
            $route(val) {
                if (this.activeHomeIndex.indexOf(val.name) === -1) {
                    this.activeIndex = '';
                } else {
                    if (val.name === 'qualityCourseHomePublicClassDetail') {
                        this.activeIndex = 'qualityCourseHomePublicClass'
                    } else {
                        this.activeIndex = val.name;
                    }
                }

            }
        },
        methods: {
            handleLogin(command) {
                if (command === 't') {
                    this.$router.push('/user/teacherLogin');
                } else if (command === 's') {
                    this.$router.push('/user/login');
                }
            },
            enterCourse() {
                if (this.role === 3) {
                    this.$router.push('/qualityCourse/teacher/index');
                } else if (this.role === 4) {
                    this.$router.push('qualityCourse/courseindex');
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .header-wrapper {
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #dcdfe6;
        .header {
            position: relative;
            width: 1200px;
            height: 60px;
            line-height: 60px;
            margin: 0 auto;
            display: flex;
            .fixed-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .header-logo {
                    width: 200px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .nav {
                    width: 1%;
                    flex: 1;
                    margin: 0 30px;
                    .el-menu-item {
                        padding: 0;
                        .nav-item {
                            display: block;
                            padding: 0 20px;
                        }
                    }
                }
                .home-login-btn {
                    height: 100%;
                    background-color: #E21D1A;
                    border-color: #E21D1A;
                }
            }
            /*.logo {*/
            /*    !*width: 200px;*!*/
            /*    font-size: 18px;*/
            /*    font-weight: 700;*/
            /*    margin-right: 20px;*/
            /*    overflow: hidden;*/
            /*}*/
            /*.user {*/
            /*    text-align: right;*/
            /*    margin-left: 20px;*/
            /*    span {*/
            /*        font-size: 16px;*/
            /*        margin-right: 20px;*/
            /*    }*/
            /*}*/
        }
    }
</style>
