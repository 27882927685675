<template>
    <div class="featured-content">
        <div class="featured-index">
            <div class="featured-left">
                <div class="featured-item">
                    <div class="featured-list">
                        <draggable :list="featuredCourseList"
                                   :group="{name: 'resources', pull: 'clone', put: false }"
                                   :sort="false" :move="onMove" @end="onEnd" class="dragg-box">
                            <div class="featured-list-item" v-for="(item,index) in featuredCourseList" :key="index"
                                 @click="getResourceList(item.index)">
                                <el-popover
                                        popper-class="temp-popover"
                                        placement="bottom"
                                        width="200"
                                        trigger="click">
                                    <div slot="reference" class="inner-box">
                                        <div class="item-cover course-ware" :class="item.class">
                                            <!--                                    <i class="iconfont" v-html="item.icon"></i>-->
                                            <img :src="item.src" alt="">
                                        </div>
                                        <span class="item-name">{{item.title}}</span>
                                    </div>
                                    <draggable :list="showResourseList"
                                               :group="{name: 'resources', pull: 'clone', put: false }"
                                               :sort="false" :move="onMove1" @end="onEnd" class="dragg-box">
                                        <div class="popover-item" v-for="item in showResourseList">
                                            <div @click="goToCourseWare(item)">{{item.title}}</div>
                                        </div>
                                    </draggable>
                                    <div class="no-data" v-if="showResourseList.length === 0">暂无数据</div>
                                </el-popover>
                            </div>
                        </draggable>
                    </div>
                </div>
                <div class="featured-topbox">
                    <span>资源类型：</span>
                    <span :class="classId === 0 ? 'class-item' : ''" @click="changeType(0)">全部</span>
                    <span :class="classId === item.id ? 'class-item' : ''" @click="changeType(item.id)"
                          v-for="item in newTypeList">{{item.name}}</span>
                </div>
                <el-scrollbar class="featured-top" :native="false">
                    <div class="featured-mainbox">
                        <div class="mainbox-list" v-if="showPeitao.length > 0">
                            <draggable :list="showPeitao"
                                       :group="{name: 'resources', pull: 'clone', put: false }"
                                       :sort="false" :move="onMove1" @end="onEnd" class="dragg-box">
                                <div class="mainbox-list-item" v-for="item in showPeitao" @click="goToCourseWare(item)">
                                    <div class="pic-box" v-if="item.material_type_id">
                                        <img :src="newTypePic[item.material_type_id - 1].src" alt="">
                                    </div>
                                    <div class="pic-box" v-else>
                                        <img :src="newTypePic[item.material_type_id].src" alt="">
                                    </div>
                                    <div class="item-title">{{item.resource_title}}</div>
                                </div>
                            </draggable>
                        </div>
                        <div class="mainbox-list" v-else>
                            <div class="no-data">暂无数据</div>
                        </div>
                        <div class="page-box">
                            <el-pagination
                                    v-if="showPeitao.length > 0"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="1"
                                    :page-size="10"
                                    layout="prev, pager, next, jumper"
                                    :total="cPeitao.length">
                            </el-pagination>
                        </div>
                    </div>
                </el-scrollbar>


                <div class="module-content" v-show="showList">
                    <el-scrollbar class="module" :native="false" :vertical="true">
                        <div class="module-list">
                            <div :class="JSON.stringify(item)!=='{}'?'new-module-item':'module-item'"
                                 v-for="(item,index) in module_listArr" :key="index">
                                <div class="item-cover1 course-ware">
<!--                                    <div class="show-img" v-if="(Object.keys(item).length != 0 && item.oldIndex) || (Object.keys(item).length != 0 && item.meterial_type_id)">-->
                                    <div class="show-img" v-if="(item && item.oldIndex) || (item && item.meterial_type_id)">
                                        <img v-if="item.meterial_type_id === undefined"
                                             :src="showCourseList[item.oldIndex - 1].src" alt=""
                                             class="img-box">
                                        <img v-if="item.meterial_type_id" src="../../assets/images/peitao.png"
                                             alt=""
                                             class="img-box">
                                    </div>
                                    <div class="show-img" v-if="item.oldIndex == 0">
                                        <img :src="showCourseList[3].src" alt="" class="img-box">
                                    </div>
                                    <!--                                    <div class="show-img" v-if="item.src">-->
                                    <!--                                        <img :src="item.src" alt="" class="img-box">-->
                                    <!--                                    </div>-->
                                    <div v-show="JSON.stringify(item)!=='{}'" class="del-btn"
                                         @click="delModule(item,index)">x
                                    </div>
                                </div>
                            </div>
                            <draggable :list="select_list" :group="{name: 'resources'}"
                                       style="display: flex;flex-direction: row;opacity: 1;position: absolute">
                                <div class="module-item" style="opacity: 0" v-for="(item,index) in select_list"
                                     :key="index">
                                    <div class="item-cover course-ware" :class="item?item.class:''">
                                        <i class="iconfont" v-html="item?item.icon:''"></i>
                                    </div>
                                    <el-tooltip v-show="JSON.stringify(item)!=='{}'" class="item" effect="light"
                                                :content="item && item.title" placement="top" popper-class="tooltip">
                                        <div class="show-title"></div>
                                    </el-tooltip>
                                </div>
                            </draggable>
                        </div>
                    </el-scrollbar>
                    <el-dialog @close="closeTemplateBtn" custom-class="saveTemplate" title="保存模板"
                               :visible.sync="saveTemplateShow">
                        <div class="template-box">
                            <div class="template-box-name">
                                <span>模板名称：</span>
                                <el-input v-model="templateName" placeholder="输入名称3-10字"
                                          style="width: 1%;flex: 1"></el-input>
                            </div>
                            <div class="template-box-btn">
                                <el-button @click="closeTemplateBtn">取消</el-button>
                                <el-button type="primary" @click="saveTemplateBtn">保存</el-button>
                            </div>
                        </div>
                    </el-dialog>
                    <div class="adhibition-btn" @click="useTempClick">
                        <span>应用</span>
                        <span>模板</span>
                    </div>
                    <div class="module-save-btn" @click="saveCourseCustom">
                        <span>保存</span>
                        <span>模板</span>
                    </div>
                    <div class="select-positioning">
                        <!--                        <div class="hover-one">-->
                        <!--                            <i class="iconfont">&#xe699;</i>-->
                        <!--                        </div>-->
                        <el-popover popper-class="temp-popover" ref="popoverRef" :disabled="tempPoverShow"
                                    placement="top-end" width="280" trigger="click">
                            <div class="popover-item" @click="selectTempClick(item)" v-for="item in courseTempList">
                                <span>{{item.name}}</span>
                                <i class="iconfont" @click.stop="deleTemp(item.id)">&#xe60a;</i>
                            </div>
                            <div v-if="courseTempList.length === 0">暂无模板</div>
                            <div class="hover-two" slot="reference">
                                <span>选择</span>
                                <span>模板</span>
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
            <el-scrollbar class="featured-right" :native="false">
                <div class="item-title">
                    <span class="text">应用中心</span>
                </div>
                <div class="app-list">
                    <div class="app-item" v-for="item in appList" @click="viewAppDetail(item)">
                        <div class="app-cover">
                            <img v-if="item.logo !== null" :src="item.logo.url" alt="" class="app-icon">
                            <img v-else src="../../assets/img/image/no_cover.png" alt="" class="app-icon">
                        </div>
                        <span class="app-name" :title="item.app_name">{{item.app_name}}</span>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <el-dialog title="欢迎使用yikeSoft" :visible.sync="dialogAppDetail" width="800px" center class="app-detail-dialog"
                   :close-on-click-modal="false">
            <div class="app-detail-content">
                <h1 class="title">欢迎使用yikeSoft</h1>
                <div class="content">
                    <span class="h2">{{appInfo.appTitle}}</span>
                    <span class="soft-introduction">软件功能介绍：</span>
                    <div class="soft-content">{{appInfo.appContent}}</div>
                </div>
            </div>
            <div slot="footer" class="info-dialog-footer">
                <a :href="appInfo.home_link" target="_blank" class="home">首页登录</a>
                <a :href="appInfo.shop_link" target="_blank" class="shop">商家登录</a>
                <a :href="appInfo.teacher_link" target="_blank" class="shop">教师登录</a>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import draggable from "vuedraggable";
    import _ from "underscore";

    export default {
        name: "teacherFeatured",
        props: ['courseInfos', 'nodeIndex', 'chapterIndex', 'select_list', 'module_list', 'courseTempList'],
        components: {
            draggable
        },
        data() {
            return {
                module_listArr: [],
                showResourseList: [],
                showPeitao: [],
                classId: 0,
                //选择的课程id
                select_course_id: this.$route.query.course_id,
                //精选课程
                featuredCourseList: [
                    {
                        class: 'courseware',
                        icon: '&#xe677;',
                        src: require('../../assets/images/courseware.png'),
                        title: '课件',
                        index: 1,
                        mtype: 0,
                    },
                    {
                        class: 'textbook',
                        icon: '&#xe687;',
                        src: require('../../assets/images/textbook.png'),
                        title: '教材',
                        index: 2,
                        mtype: 0,
                    },
                    {
                        class: 'micro-lesson',
                        icon: '&#xe688;',
                        src: require('../../assets/images/micro-lesson.png'),
                        title: '微课',
                        index: 3,
                        mtype: 0,
                    },
                    {
                        class: 'animation',
                        icon: '&#xe68e;',
                        src: require('../../assets/images/animation.png'),
                        title: '教学设计',
                        index: 4,
                        mtype: 0,
                    },
                    {
                        class: 'test-center',
                        icon: '&#xe678;',
                        src: require('../../assets/images/test-center.png'),
                        title: '测试中心',
                        index: 6,
                        mtype: 0,
                    },
                    {
                        class: 'training-center',
                        icon: '&#xe66f;',
                        src: require('../../assets/images/training-center.png'),
                        title: '实训中心',
                        index: 7,
                        mtype: 0,
                    },
                    {
                        class: 'person-resource',
                        icon: '&#xe65d;',
                        src: require('../../assets/images/person-resource.png'),
                        title: '个人资源',
                        index: 8,
                        mtype: 0,
                    },
                ],
                showCourseList: [
                    {
                        class: 'courseware',
                        icon: '&#xe677;',
                        src: require('../../assets/images/courseware.png'),
                        title: '课件',
                        index: 1,
                        mtype: 0,
                    },
                    {
                        class: 'textbook',
                        icon: '&#xe687;',
                        src: require('../../assets/images/textbook.png'),
                        title: '教材',
                        index: 2,
                        mtype: 0,
                    },
                    {
                        class: 'micro-lesson',
                        icon: '&#xe688;',
                        src: require('../../assets/images/micro-lesson.png'),
                        title: '微课',
                        index: 3,
                        mtype: 0,
                    },
                    {
                        class: 'animation',
                        icon: '&#xe68e;',
                        src: require('../../assets/images/animation.png'),
                        title: '教学设计',
                        index: 4,
                        mtype: 0,
                    },
                    {
                        class: 'peitao',
                        icon: '&#xe68e;',
                        src: require('../../assets/images/peitao.png'),
                        title: '配套材料',
                        index: 5,
                        mtype: 0,
                    },
                    {
                        class: 'test-center',
                        icon: '&#xe678;',
                        src: require('../../assets/images/test-center.png'),
                        title: '测试中心',
                        index: 6,
                        mtype: 0,
                    },
                    {
                        class: 'training-center',
                        icon: '&#xe66f;',
                        src: require('../../assets/images/training-center.png'),
                        title: '实训中心',
                        index: 7,
                        mtype: 0,
                    },
                    {
                        class: 'person-resource',
                        icon: '&#xe65d;',
                        src: require('../../assets/images/person-resource.png'),
                        title: '个人资源',
                        index: 8,
                        mtype: 0,
                    },
                ],
                //应用列表
                appList: [],
                //是否显示上传资料弹窗
                dialogUploadMaterial: false,
                //上传资料数据表单
                uploadMaterialForm: {
                    select_resources_type: '',
                    select_course_class: '',
                    title: '',
                    resource_file_type: '',
                    file: '',
                    fileName: '',
                    file_path: '',
                    pdfFile: '',
                    pdfName: '',
                    file_preview_path: '',
                },
                uploadMaterialRules: {
                    select_resources_type: [
                        {required: true, message: '请选择资源类型', trigger: 'change'}
                    ],
                    select_course_class: [
                        {required: true, message: '请选择课程分类', trigger: 'change'}
                    ],
                    file: [
                        {required: true, message: '请上传文件', trigger: 'change'}
                    ],
                    title: [
                        {required: true, message: '请输入标题', trigger: 'blur'},
                        {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
                    ],
                },
                formLabelWidth: '100px',
                //课程分类
                adminResourcesClassList: [],
                resourcesClassProps: {
                    children: 'children',
                    label: 'name',
                    value: 'index'
                },
                //资源类型
                adminResourcesTypeList: [],
                //是否要上传PDF 文件
                isUploadPDF: false,
                isPDFInput: false,
                typeList: [],
                newTypeList: [{
                    id: 1,
                    name: '知识点'
                }, {
                    id: 2,
                    name: "素材库"
                }, {
                    id: 3,
                    name: '案例库'
                }, {
                    id: 4,
                    name: '视频'
                }, {
                    id: 5,
                    name: '相关文章'
                }],
                newTypePic: [{
                    id: 1,
                    src: require('../../assets/img/image/knowledge_point.png')
                }, {
                    id: 2,
                    src: require('../../assets/img/image/sucai.png')
                }, {
                    id: 3,
                    src: require('../../assets/img/image/anli.png')
                }, {
                    id: 4,
                    src: require('../../assets/img/image/video.png')
                }, {
                    id: 5,
                    src: require('../../assets/img/image/related_articles.png')
                }],
                cKejian: [],
                cJiaocai: [],
                cWeike: [],
                cDonghua: [],
                cPeitao: [],
                cJiaoan: [],
                cZhishidian: [],
                cSucaiku: [],
                cAnliku: [],
                cPeiWeike: [],
                cXiangguanwenzhang: [],
                cPersonResource: [],
                testCenter: [],
                practiceCenter: [],
                //是否显示查看应用详情弹窗
                dialogAppDetail: false,
                //应用信息
                appInfo: {
                    appTitle: '',
                    appContent: '',
                    home_link: '',
                    shop_link: '',
                    teacher_link: '',
                },
                resourceList: [],
                papersList: [],
                projectsList: [],
                courseContent: [],
                material_list: [],
                course_content: [],
                showList: true,
                saveTemplateShow: false,
                templateName: '',
                selectTemplateList: [],
                tempPoverShow: false,
            }
        },
        mounted() {
            this.getTypeList();
            this.getAllocation();
          // this.module_listArr = this.module_list
          this.setNull();
        },
        watch: {
            nodeIndex(d) {
                this.setNull();
            },
            chapterIndex(d) {
                this.setNull();
            },
            $route() {
                this.select_course_id = this.$route.query.course_id
            },
            module_list: function (newQuestion, oldQuestion) {
                this.module_listArr = newQuestion
                for (let i = 0; i < this.module_listArr.length; i++) {
                    if (!this.module_listArr[i]) {
                        this.module_listArr[i] = {}
                    }
                }
                this.$emit('handleList', this.module_listArr);
            },
            // courseTempList: function (newQuestion, oldQuestion) {
            //     if (!newQuestion[0]) return
            //     this.selectTempClick(newQuestion[0])
            // }
        },
        methods: {
            setNull() {
                let arr = new Array(14).fill({});
                this.$emit('handleList', arr);
                this.courseInfo();
            },
            //查看应用详情
            viewAppDetail(item) {
                this.dialogAppDetail = true;
                this.appInfo.appTitle = item.app_name;
                this.appInfo.appContent = item.intro;
                this.appInfo.home_link = item.home_link;
                this.appInfo.shop_link = item.shop_link;
                this.appInfo.teacher_link = item.teacher_link;
            },
            getAllocation() {
                this.$thttp.axiosGet(this.$api.getAllocation, res => {
                    if (res.code = 200) {
                        if (res.data.appcenter_ids) {
                            let appcenter_ids = JSON.parse(res.data.appcenter_ids);
                            appcenter_ids.forEach(item => {
                                this.getAppList(item.school_app_id)
                            })
                        }
                    }
                })
            },
            getAppList(id) {
                this.$thttp.axiosGetBy(this.$api.app_showApp, {id: id ? id : 0}, res => {
                        if (res.code = 200) {
                            this.appList.push(res.data.data[0])
                        }

                        //应用排序，排列顺序跟总管理员应用中心一样
                        function compare(property) {
                            return function (a, b) {
                                let value1 = a[property];
                                let value2 = b[property];
                                return value2 - value1;
                            }
                        }

                        this.appList.sort(compare('parent_id'));
                    }
                )
            },
            getTypeList() {
                this.$thttp.axiosGet(this.$api.meterial_mtlTypeList, res => {
                    if (res.code == 200) {
                        this.typeList = res.data;
                        this.typeList.forEach(item => {
                            item.oldIndex = 8;
                            if (item.id == 1) {
                                item.icon = '&#xe733;';
                                item.mtype = 1;
                                item.title = item.name;
                                item.showId = 1;
                            }
                            if (item.id == 2) {
                                item.icon = '&#xeb62;';
                                item.mtype = 1;
                                item.showId = 2;
                                item.title = item.name;
                            }
                            if (item.id == 3) {
                                item.icon = '&#xeb61;';
                                item.mtype = 1;
                                item.showId = 3;
                                item.title = item.name;
                            }
                            if (item.id == 4) {
                                item.icon = '&#xeb63;';
                                item.mtype = 1;
                                item.showId = 4;
                                item.title = item.name;
                            }
                            if (item.id == 5) {
                                item.icon = '&#xe625;';
                                item.mtype = 1;
                                item.showId = 5;
                                item.title = item.name;
                            }
                            if (item.id == 8) {
                                item.icon = '&#xe65e;';
                                item.mtype = 1;
                                item.showId = 8;
                                item.title = item.name;
                            }
                        })
                        //有时typeList请求较慢，比对数据时会缺失，所以比对放到取完数据后执行
                        this.courseInfo();
                    }
                })
            },

            courseInfo() {
                //
                Promise.all([
                    this.courseInfoByPromise(this.select_course_id),
                    this.courseInfoCustom(this.select_course_id, this.chapterIndex, this.nodeIndex),
                    this.selectClassify(),
                ]).then(([res, custom, material]) => {
                    this.courseName = res.course_name;
                    this.resourceList = res.reslist;
                    this.papersList = res.papers;
                    this.projectsList = res.projects;
                    this.courseContent = res.course_content[this.chapterIndex].children[this.nodeIndex].children;
                    this.material_list = material;
                    // console.log('课程的平台资源', this.resourceList);
                    //整理资源
                    //课件
                    this.cKejian = this.formatResources(1);
                    // console.log('1222', this.cKejian);
                    //教材
                    this.cJiaocai = this.formatResources(2);
                    // console.log('2', this.cJiaocai);
                    //微课
                    this.cWeike = this.formatResources(3);
                    // console.log('3', this.cWeike);
                    //动画
                    this.cDonghua = this.formatResources(4);
                    // console.log('4', this.cDonghua);
                    //配套材料
                    this.cPeitao = this.formatResources(5);
                    this.handleShowPeiTao();
                    // console.log('5', this.cPeitao);
                    //教案
                    this.cJiaoan = this.formatResources(6);
                    // console.log('6', this.cJiaoan);
                    //测试中心
                    this.testCenter = this.formatExams(res.papers);
                    // console.log('小测', this.testCenter);
                    //实训中心
                    this.practiceCenter = this.formatProject(res.projects);
                    // console.log('实训', this.practiceCenter);
                    this.cZhishidian = this.formatMaterial(1, 1)//知识点
                    this.cSucaiku = this.formatMaterial(2, 2)//素材库
                    this.cAnliku = this.formatMaterial(3, 3)//案例库
                    this.cPeiWeike = this.formatMaterial(4, 4)//微课
                    this.cXiangguanwenzhang = this.formatMaterial(5, 5)//相关文章
                    this.cPersonResource = this.formatMaterial(8, 6)//个人资源
                    // console.log('个人资源', this.cPersonResource)
                    let obj = {};
                    obj.cKejian = this.cKejian;
                    obj.cJiaocai = this.cJiaocai;
                    obj.cWeike = this.cWeike;
                    obj.cDonghua = this.cDonghua;
                    obj.cPeitao = this.cPeitao;
                    obj.cJiaoan = this.cJiaoan;
                    obj.testCenter = this.testCenter;
                    obj.practiceCenter = this.practiceCenter;
                    obj.cZhishidian = this.cZhishidian;
                    obj.cSucaiku = this.cSucaiku;
                    obj.cAnliku = this.cAnliku;
                    obj.cPeiWeike = this.cPeiWeike;
                    obj.cXiangguanwenzhang = this.cXiangguanwenzhang;
                    obj.cPersonResource = this.cPersonResource;
                    this.$emit('savaData', obj);
                    this.course_content = custom.course_content;
                    //如果有自定义排版，按自定义排版来
                    if (this.course_content && this.course_content.length > 0) {
                        this.handleCourseCustom(custom);
                    } else {//默认是平铺所有资源，何耀明说的
                        this.fillAll();
                    }
                }).catch(err => {
                    this.$common.axiosErrorMsg(err);
                });
            },
            courseInfoCustom(course_id, chapteIndex, nodeIndex) {
                return new Promise((resolve, reject) => {
                    let param = {
                        course_id: course_id,
                        course_chapter: chapteIndex,
                        course_node: nodeIndex
                    }
                    this.$thttp.axiosGetBy(this.$api.course_getcoursecustom, param, (res) => {
                        if (res.code === 200) {
                            resolve(res.data);
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.msg,
                                duration: 1000
                            });
                            resolve(false);
                        }
                    }, (err) => {
                        reject(err);
                    })
                })
            },
            courseInfoByPromise(id) {
                return new Promise((resolve, reject) => {
                    let param = {
                        id
                    }
                    this.$thttp.axiosGetBy(this.$api.courseinfo, param, (res) => {
                        if (res.code === 200) {
                            resolve(res.data);
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.msg,
                                duration: 1000
                            });
                            resolve(false);
                        }
                    }, (err) => {
                        reject(err);
                    })
                });
            },
            handleCourseCustom(val) {
                let arr = this.select_list;
                //查询到的自定义排版
                this.$emit('savaId', val.id)
                this.course_content.forEach(item => {
                    //oldIndex是为了分辨单独拖拽进去的
                    if (item.oldIndex) {
                        //1到5是上面一行的精选课程
                        if (item.type < 6) {
                            arr[item.index] = this.showCourseList[item.oldIndex - 1]
                        } else if (item.type == 6) {//6是配套材料
                            arr[item.index] = this.material_list[item.oldIndex - 1];
                        }
                    } else {
                        if (item.type == 3) {
                            this.resourceList.forEach(item1 => {
                                if (item1.resource_id == item.id) {
                                    let obj = item1;
                                    obj.ctype = item.type;
                                    obj.id = item1.resource_id;
                                    obj.type = item1.resource_type_id;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 4) {
                            this.papersList.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = item2;
                                    obj.ctype = item.type;
                                    obj.type = 6;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 5) {
                            this.projectsList.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = item2;
                                    obj.ctype = item.type;
                                    obj.type = 7;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 6) {
                            this.material_list.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = item2;
                                    obj.ctype = item.type;
                                    arr[item.index] = obj;
                                }
                            })
                        }
                    }
                })
                this.$emit('handleList', arr);
                this.showList = false;
                this.showList = true;
            },
            //后期可优化，暂时这么写
            fillAll() {
                let arr = [];
                this.cKejian.forEach(item => {
                    let tmp = {};
                    if (item) {
                        tmp = item;
                        tmp.type = 1;
                        // delete tmp.oldIndex;

                        arr.push(tmp)
                    }
                });
                this.cJiaocai.forEach(item => {
                    let tmp = {};
                    if (item) {
                        tmp = item;
                        tmp.type = 2;
                        // delete tmp.oldIndex;

                        arr.push(tmp)
                    }
                });
                this.cWeike.forEach(item => {
                    let tmp = {};
                    if (item) {
                        tmp = item;
                        tmp.type = 3;
                        //delete tmp.oldIndex;

                        arr.push(tmp)
                    }
                });
                this.cJiaoan.forEach(item => {
                    let tmp = {};
                    if (item) {
                        tmp = item;
                        tmp.type = 4;
                        //delete tmp.oldIndex;

                        arr.push(tmp)
                    }
                });
                this.cPeitao.forEach(item => {
                    let tmp = {};
                    if (item) {
                        tmp = item;
                        tmp.type = 5;

                        arr.push(tmp)
                    }
                });
                this.testCenter.forEach(item => {
                    let tmp = {};
                    if (item) {
                        tmp = item;
                        tmp.type = 6;
                        //delete tmp.oldIndex;

                        arr.push(tmp)
                    }
                });
                this.practiceCenter.forEach(item => {
                    let tmp = {};
                    if (item) {
                        tmp = item;
                        tmp.type = 7;
                        //delete tmp.oldIndex;
                        arr.push(tmp)
                    }
                });
                this.cPersonResource.forEach(item => {
                    let tmp = {};
                    if (item) {
                        tmp = item;
                        //delete tmp.oldIndex
                        arr.push(tmp)
                    }
                });
                let len = arr.length;
                //因为我只画了14个格子
                if (len > 14) {
                    arr.splice(14, len - 14)
                } else {
                    let newArr = new Array(14 - len).fill({})
                    arr.push.apply(arr, newArr)
                }
                this.$emit('handleList', arr)
            },
            canotDragger(msg) {
                this.$message({
                    type: 'warning',
                    message: `当前节下的${msg}只有一个，支持直接拖拽`,
                    duration: 1000
                });
            },
            formatExams(exams) {
                let reslist = [];
                let reslist1 = [];
                _.each(exams, (item) => {
                    let tmp = {}
                    tmp = item;
                    tmp.title = item.paper_name;
                    tmp.ctype = 4;
                    tmp.oldIndex = 6;
                    reslist.push(tmp)
                });
                //遍历出当前章节的资源
                _.each(this.courseContent, (item) => {
                    _.each(reslist, (item1) => {
                        if (item.type === 4) {
                            if (Number(item.id) === Number(item1.id)) {
                                reslist1.push(item1);
                            }
                        }
                    })
                })
                return reslist1;
            },
            formatProject(projects) {
                let reslist = [];
                let reslist1 = [];
                _.each(projects, (item) => {
                    let tmp = {};
                    tmp = item;
                    tmp.id = item.id;
                    tmp.ctype = 5;
                    tmp.title = item.project_title;
                    tmp.oldIndex = 7;
                    reslist.push(tmp);
                });
                //遍历出当前章节的资源
                _.each(this.courseContent, (item) => {
                    _.each(reslist, (item1) => {
                        if (item.type === 5) {
                            if (Number(item.id) === Number(item1.id)) {
                                reslist1.push(item1);
                            }
                        }
                    })
                })
                return reslist1;
            },
            formatResources(type) {
                let reslist = [];
                let reslist1 = [];
                //格式化资源的属性名，这边是查出所有的资源
                _.each(this.resourceList, (item) => {
                    let tmp = {}
                    if (Number(item.resource_type_id) === Number(type)) {
                        tmp = item;
                        tmp.id = item.resource_id;         //资源id
                        tmp.title = item.resource_title;      // 资源名称
                        tmp.ctype = 3;                         //资源位于课程内容中的标识：系统资源 => 3; 系统测试(小测) => 4；项目（实训） => 5；配套材料（教师和学校管理员自己上传）=> 6
                        tmp.oldIndex = type === 6 ? item.material_type_id : type;
                        tmp.type = 3;
                        reslist.push(tmp);
                    }
                });
                //遍历出当前章节的资源
                _.each(this.courseContent, (item) => {
                    _.each(reslist, (item1) => {
                        if (Number(item.id) === Number(item1.id)) {
                            reslist1.push(item1);
                        }
                    })
                })
                return reslist1;
            },
            formatMaterial(type, index) {
                let reslist = [];
                //格式化资源的属性名
                _.each(this.material_list, (item) => {
                    let tmp = {}
                    if (Number(item.meterial_type_id) === Number(type)) {
                        tmp = item;
                        tmp.id = item.id;         //资源id
                        tmp.ctype = 6;                         //资源位于课程内容中的标识：系统资源 => 3; 系统测试(小测) => 4；项目（实训） => 5；配套材料（教师和学校管理员自己上传）=> 6
                        tmp.oldIndex = index;
                        tmp.title = item.title;
                        reslist.push(tmp);
                    }
                });
                return reslist;
            },
            //获取课程详情
            getCourseInfo() {
                let params = {
                    id: this.select_course_id
                };
                this.$thttp.axiosGetBy(this.$api.courseinfo, params, (res) => {
                    if (res.code === 200) {
                        let course_content = res.data.course_content;
                        let tmpObj = {};
                        let childrenObj = {};
                        for (let i = 0; i < course_content.length; i++) {
                            tmpObj = {
                                children: [],
                                name: course_content[i].name,
                                index: i,
                            }
                            let childrenArr = course_content[i].children;
                            for (let j = 0; j < childrenArr.length; j++) {
                                childrenObj = {
                                    name: childrenArr[j].name,
                                    index: j,
                                }
                                tmpObj.children.push(childrenObj)
                            }
                            this.adminResourcesClassList.push(tmpObj)
                        }
                        // console.log('课程章节', this.adminResourcesClassList)

                    }
                }, (error) => {
                    console.log(error);
                })
            },
            //获取资源分类列表
            getResourcesTypeList() {
                this.$thttp.axiosGet(this.$api.meterial_mtlTypeList, (res) => {
                    if (res.code === 200) {
                        this.adminResourcesTypeList = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            selectClassify() {
                return new Promise((resolve, reject) => {
                    let param = {
                        course_id: this.select_course_id,
                        course_chapter: this.chapterIndex,
                        course_node: this.nodeIndex
                    }
                    this.$thttp.axiosGetBy(this.$api.meterial_showMeterialList, param, (res) => {
                        if (res.code === 200) {
                            resolve(res.data.data);
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.msg,
                                duration: 1000
                            });
                            resolve(false);
                        }
                    }, (err) => {
                        reject(err);
                    })
                })
            },
            getResourceList(index) {
                this.showResourseList = [];
                switch (index) {
                    case 1:
                        this.showResourseList = this.cKejian;
                        break;
                    case 2:
                        this.showResourseList = this.cJiaocai;
                        break;
                    case 3:
                        this.showResourseList = this.cWeike;
                        break;
                    case 4:
                        this.showResourseList = this.cJiaoan;
                        break;
                    case 6:
                        this.showResourseList = this.testCenter;
                        break;
                    case 7:
                        this.showResourseList = this.practiceCenter;
                        break;
                    case 8:
                        this.$router.push({
                            path: '/qualityCourse/teacher/teacherMatingDetail',
                            query: {
                                course_id: this.select_course_id,
                                zhangIndex: this.chapterIndex,
                                jieIndex: this.nodeIndex,
                            }
                        })
                        break;
                }
            },
            //跳到课件列表页
            goToCourseWare(item) {
                this.$router.push({
                    path: '/qualityCourse/teacher/teacherCourseWareDetail',
                    query: {
                        file_path: item.file_preview_path,
                        typeId: item.oldIndex,
                        zhangIndex: this.chapterIndex,
                        jieIndex: this.nodeIndex,
                        course_id: this.select_course_id,
                        id: item.id
                    }
                });
            },
            onMove(evt) {
                let obj = evt.draggedContext.element;
                let index = evt.draggedContext.index + 1;
                let res = null;
                //多个的不允许拖拽
                if (index == 7) {
                    res = this.toMaterial(6);
                } else {
                    res = this.toLists(index);
                }
                if (res[0] == false) {
                    return false;
                } else {
                    obj = res[1]
                }
                let arr = JSON.parse(JSON.stringify(this.select_list))
                for (let i = 0; i < arr.length; i++) {
                    if (JSON.stringify(arr[i]) === JSON.stringify(obj)) {
                        return false;
                    }
                }
                if (arr.includes(obj)) {
                    return false;
                }
            },
            onMove1(evt) {
                let obj = evt.draggedContext.element;
                //多个的不允许拖拽
                // if(this.showResourseList.length === 1){
                //     return false;
                // }
                let arr = JSON.parse(JSON.stringify(this.select_list))
                for (let i = 0; i < arr.length; i++) {
                    if (JSON.stringify(arr[i]) === JSON.stringify(obj)) {
                        return false;
                    }
                }
            },
            onEnd(evt) {
                console.log(evt)
                let index = evt.oldIndex + 1;
                let newIndex = evt.newIndex;
                console.log('index', index)
                console.log('newIndex', newIndex)
                let obj = {};
                let res = null;
                if (index == 7) {
                    res = this.toMaterial(6);
                } else {
                    res = this.toLists(index);
                }
                if (res && res[0] == true) {
                    obj = res[1];
                    if (!this.select_list.includes(obj)) {
                        this.select_list[newIndex] = obj;
                    }
                }
                if (this.select_list.length > 14) {
                    this.select_list.splice(newIndex + 1, 1)
                }
                this.module_listArr = this.select_list;
                this.$emit('handleList', this.select_list);
            },
            //查询资源数
            toLists(rtype) {
                //平台资源 resource_type_id : 课件 => 1 , 教材 => 2, 微课 => 3, 动画 => 4, 配套材料 => 5, 教案 => 6
                //课程内容中的标识： 平台资源 => 3，小测 => 4，项目 => 5；配套材料（学校管理员和学校教师自己上传的资源）=> 6
                let type = Number(rtype);
                switch (type) {
                    case 1:
                        return this.toDetailBefore(this.cKejian, '课件', 1, 3);
                        break;
                    case 2:
                        return this.toDetailBefore(this.cJiaocai, '教材', 2, 3);
                        break;
                    case 3:
                        return this.toDetailBefore(this.cWeike, '微课', 3, 3);
                        break;
                    case 4:
                        return this.toDetailBefore(this.cJiaoan, '动画', 4, 3);
                        break;
                    case 5:
                        return this.toDetailBefore(this.cPeitao, '配套材料', 5, 3);
                        break;
                    case 6:
                        return this.toDetailBefore(this.testCenter, '测试中心', 7, 4);
                        break;
                    case 7:
                        return this.toDetailBefore(this.practiceCenter, '', 8, 5);
                        break;
                }
            },
            toMaterial(rtype) {
                let type = Number(rtype);
                switch (type) {
                    case 1:
                        return this.toDetailBefore(this.cZhishidian, '知识点', 1, 6);
                        break;
                    case 2:
                        return this.toDetailBefore(this.cSucaiku, '素材库', 2, 6);
                        break;
                    case 3:
                        return this.toDetailBefore(this.cAnliku, '案例库', 3, 6);
                        break;
                    case 4:
                        return this.toDetailBefore(this.cPeiWeike, '微课', 4, 6);
                        break;
                    case 5:
                        return this.toDetailBefore(this.cXiangguanwenzhang, '相关文章', 5, 6);
                        break;
                    case 6:
                        return this.toDetailBefore(this.cPersonResource, '个人资源', 8, 6);
                        break;
                }
            },
            //判断是否只有一个资源
            toDetailBefore(resources, msg = '', type, csType) {
                let res = '';
                let obj = {};
                let arr = [];
                if (resources.length !== 0) {
                    // this.canotDragger(msg);
                    res = true;
                    obj = resources[0]
                } else {
                    // this.setResourceListToVuex(resources, type, csType);
                    res = false;
                }
                arr.push(res, obj)
                return arr;
            },
            saveCourseCustom() {
                // this.$emit('handleSave', true)
                // console.log(1011,this.module_list);
                // console.log(1012,this.cKejian)

                let list = [];
                if (this.module_listArr.length) {
                    this.module_listArr.forEach((item, index) => {
                        if (JSON.stringify(item) !== '{}') {
                            if (item.ctype && item.id) {
                                let obj = {
                                    type: item.ctype,
                                    id: item.id,
                                    index: index,
                                }
                                if (item.icon) {
                                    obj.oldIndex = item.index;
                                }
                                list.push(obj)
                            } else {
                                let formatList = [];
                                if (item.title === '测试中心') {
                                    formatList = this.testCenter
                                } else if (item.title === '实训中心') {
                                    formatList = this.practiceCenter
                                } else {
                                    formatList = this.formatResources(item.index);
                                }
                                if (formatList.length) {
                                    formatList.forEach((item2, index2) => {
                                        let obj2 = {
                                            type: item2.ctype,
                                            id: item2.id,
                                            index: index,
                                        }
                                        if (item.icon) {
                                            obj2.oldIndex = item.index;
                                        }
                                        list.push(obj2)
                                    })
                                }
                            }
                        }
                    })
                }
                this.selectTemplateList = list;
                if (this.selectTemplateList.length) {
                    this.saveTemplateShow = true;
                } else {
                    this.$message.warning('请选择至少一个模板！')
                }
            },
            saveTemplateBtn() {
                if (this.templateName.length < 3 || this.templateName.length > 10) {
                    this.$message.warning('名称长度为3-10个字！');
                    return
                }
                let course_content = JSON.stringify(this.selectTemplateList)
                let param = {
                    course_id: this.select_course_id,
                    course_chapter: this.chapterIndex,
                    course_node: this.nodeIndex,
                    name: this.templateName,
                    course_content: course_content
                }
                this.$thttp.axiosPost(this.$api.course_saveCourseTemp, param, res => {
                    // console.log(1078,res)
                    if (res.code === 200) {
                        this.$message({
                            message: '保存成功！',
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.$emit('updateTempList');
                                this.saveTemplateShow = false
                            }
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            closeTemplateBtn() {
                this.saveTemplateShow = false;
                this.templateName = ''
            },
            delModule(item, index) {
                this.select_list[index] = {};
                this.module_listArr = this.select_list;
                this.$emit('handleList', this.select_list);
                this.$forceUpdate();
            },
            handleTabClick() {

            },
            useTempClick() {
                this.$emit('handleSave', true)
            },
            selectTempClick(item) {
                // console.log(1127,item)
                if (!item) return
                let course_content = JSON.parse(item.course_content);
                this.handleTempCustom(course_content)
                // return
                // this.$refs.popoverRef.doClose()
            },
            //删除模板
            deleTemp(id) {
                this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$thttp.axiosGetBy(this.$api.course_delCourseTemp, {id: id}, res => {
                        // console.log(res)
                        if (res.code === 200) {
                            this.$message({
                                message: '删除成功！',
                                type: 'success',
                                duration: 1000,
                                onClose: () => {
                                    this.$emit('updateTempList')
                                }
                            })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            handleTempCustom(val) {
                // console.log(1015,this.module_list )
                let arr = new Array(14).fill({});
                //查询到的自定义排版
                val.forEach(item => {
                    //oldIndex是为了分辨单独拖拽进去的
                    if (item.oldIndex) {
                        //1到5是上面一行的精选课程
                        if (item.type < 6) {
                            arr[item.index] = this.showCourseList[item.oldIndex - 1]
                        } else if (item.type == 6) {//6是配套材料
                            arr[item.index] = this.typeList[item.oldIndex - 1];
                        }
                    } else {
                        if (item.type == 3) {
                            // console.log(this.resourceList,123)
                            this.resourceList.forEach(item1 => {
                                if (item1.resource_id == item.id) {
                                    let obj = item1;
                                    obj.ctype = item.type;
                                    obj.id = item1.resource_id;
                                    obj.type = item1.resource_type_id;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 4) {
                            this.papersList.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = item2;
                                    obj.ctype = item.type;
                                    obj.type = 6;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 5) {
                            this.projectsList.forEach(item3 => {
                                if (item3.id == item.id) {
                                    let obj = item3;
                                    obj.ctype = item.type;
                                    obj.type = 7;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 6) {
                            this.material_list.forEach(item4 => {
                                if (item4.id == item.id) {
                                    let obj = item4;
                                    obj.ctype = item.type;
                                    arr[item.index] = obj;
                                }
                            })
                        }
                    }
                })
                this.$emit('handleList', arr);
                this.showList = false;
                this.showList = true;
            },
            changeType(id) {
                this.classId = id;
                let arr = [];
                this.cPeitao.forEach(item => {
                    if (id === 0) {
                        arr.push(item)
                    } else {
                        if (item.material_type_id === id) {
                            arr.push(item)
                        }
                    }
                })
                this.showPeitao = arr;
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
            },
            handleShowPeiTao() {
                let arr = [];
                let len = this.cPeitao.length;
                if (len > 0 && len < 10) {
                    for (let i = 0; i < len; i++) {
                        arr.push(this.cPeitao[i]);
                    }
                } else if (len >= 10) {
                    for (let j = 0; j < 10; j++) {
                        arr.push(this.cPeitao[j]);
                    }
                }
                this.showPeitao = arr;
            },
            handleCurrentChange(val) {
                let arr = [];
                let pageSize = 10;
                let num = (val - 1) * pageSize;
                let endNum = (val * pageSize) - 1;
                for (let i = num; i <= endNum; i++) {
                    arr.push(this.cPeitao[i]);
                }
                let newArr = arr.filter(item => {
                    return item != undefined
                })
                this.showPeitao = newArr
            },
        }
    }
</script>

<style lang="scss">
    .tooltip {
        box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);

        &.is-light {
            border: unset;
        }

        &.el-tooltip__popper[x-placement^="top"] .popper__arrow {
            border-top-color: #ffffff;
        }
    }
</style>

<style scoped lang="scss">
    .featured-content {
        display: flex;
        justify-content: start;
        width: 100%;
        height: 100%;

        .featured-index {
            display: flex;
            justify-content: start;
            width: 100%;
            height: 100%;

            .featured-left {
                display: flex;
                flex-direction: column;
                position: relative;
                flex: 1;
                width: 1%;
                background-color: #F2F8FF;
                margin-right: 20px;

                .featured-item {
                    &:nth-child(2) {
                        margin-bottom: 64px;
                    }

                    &:nth-child(3) {
                        margin-bottom: 30px;

                        .item-title {
                            display: flex;
                            justify-content: space-between;

                            .upload-material {
                                width: 70px;
                                height: 30px;
                                background: #ECF5FF;
                                border: 1px solid #DCECFE;
                                border-radius: 4px;
                                color: #409EFF;
                                font-size: 14px;
                                text-align: center;
                                line-height: 30px;

                                &:hover {
                                    color: #409EFF;
                                    border-color: #c6e2ff;
                                    background-color: #ecf5ff;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .item-title {
                        position: relative;
                        font-size: 16px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 4px;
                            width: 4px;
                            height: 16px;
                            background-color: #409EFF;
                        }

                        .text {
                            margin-left: 15px;
                        }
                    }

                    .featured-list {
                        width: 100%;
                        height: 120px;
                        background-color: #F5F7FA;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        box-sizing: border-box;

                        .dragg-box {
                            width: 100%;
                            display: flex;
                        }

                        .featured-list-item {
                            display: flex;
                            margin-right: 30px;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: calc((100% - 75px) / 7);
                            /*min-width: 140px;*/
                            margin-right: 37px;
                            height: 120px;
                            background: #ffffff;
                            border-radius: 6px;

                            .inner-box {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }

                            &:hover {
                                box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                            }

                            .item-cover {
                                width: 50px;
                                height: 50px;
                                line-height: 40px;
                                text-align: center;
                                border-radius: 50%;
                                cursor: pointer;

                                i {
                                    font-size: 20px;
                                    color: #fff;
                                }

                                img {
                                    width: 50px;
                                    height: 50px;
                                }

                                .app-icon {
                                    max-width: 100%;
                                    max-height: 100%;
                                    border-radius: 5px;
                                }
                            }

                            &:nth-of-type(7) {
                                margin-right: 0;
                            }

                            .circle-box {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #ffffff;

                                ::v-deep .iconfont {
                                    font-size: 20px;
                                }
                            }

                            .item-name {
                                margin-top: 10px;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .module-content {
                    display: flex;
                    justify-content: space-between;
                    padding: 19px 20px;
                    /*position: absolute;*/
                    /*bottom: 20px;*/
                    /*left: 0;*/
                    /*right: 0;*/
                    align-items: center;
                    box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
                    position: relative;
                    background: #FFFFFF;

                    .module {
                        flex: 1;
                        width: 1%;

                        ::v-deep .el-scrollbar__wrap {
                            overflow-y: hidden;
                        }

                        ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                            white-space: nowrap;
                        }
                    }

                    .module-list {
                        padding: 6px;
                        display: flex;
                        position: relative;

                        .new-module-item {
                            position: relative;
                            width: 60px;
                            height: 60px;
                            border-radius: 10px;
                            margin-right: 15px;
                        }

                        .module-item {
                            position: relative;
                            width: 60px;
                            height: 60px;
                            border: 1px dashed #CCCCCC;
                            border-radius: 24px;
                            margin-right: 13px;
                        }

                        .item-cover {
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            border-radius: 50%;

                            i {
                                font-size: 20px;
                                color: #fff;
                            }
                        }

                        .item-cover1 {
                            width: 60px;
                            height: 60px;
                            line-height: 60px;
                            text-align: center;
                            border-radius: 50%;

                            i {
                                font-size: 20px;
                                color: #fff;
                            }

                            .show-img {
                                width: 100%;
                                height: 100%;
                                box-shadow: 0px 2px 9px 1px rgba(87, 77, 237, 0.17);
                                border-radius: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .img-box {
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 24px;
                                }
                            }
                        }

                        .show1 {
                            background: #61BCEB;
                        }

                        .show2 {
                            background: #3DCAC8;
                        }

                        .show3 {
                            background: #F0BC49;
                        }

                        .show4 {
                            background: #EC75A4;
                        }

                        .show5 {
                            background: #79ACDC;
                        }

                        .show6 {
                            background: #61BCEB;
                        }

                        .show7 {
                            background: #5AB9E9;
                        }

                        .courseware {
                            background: linear-gradient(0deg, #F5D971, #EDA92E);
                        }

                        .textbook {
                            background: linear-gradient(0deg, #7BCBF2, #44ACE3);
                        }

                        .lesson-plan {
                            background: linear-gradient(0deg, #53DED1, #2BB9BF);
                        }

                        .micro-lesson {
                            background: linear-gradient(0deg, #EE89B5, #EA5D90);
                        }

                        .animation {
                            background: linear-gradient(0deg, #F39E72, #EC6C6C);
                        }

                        .test-center {
                            background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
                        }

                        .training-center {
                            background: linear-gradient(0deg, #ED83AA, #EC6B6F);
                        }

                        .knowledge-points {
                            background: linear-gradient(0deg, #77C9F1, #47AEE4);
                        }

                        .material-library {
                            background: linear-gradient(0deg, #51DCD0, #2CBAC0);
                        }

                        .case-library {
                            background: linear-gradient(0deg, #F5D971, #EDA92E);
                        }

                        .related-copywriting {
                            background: linear-gradient(0deg, #8BC3EB, #628EC9);
                        }

                        .personal-info {
                            background: linear-gradient(0deg, #51DCD0, #2FBDC1);
                        }

                        .del-btn {
                            width: 18px;
                            height: 18px;
                            background: #FF0000;
                            color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            opacity: 0;
                            cursor: pointer;
                            z-index: 1;
                        }

                        .show-title {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            z-index: 1;
                        }

                        .new-module-item:before {
                            content: "";
                            width: 0;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            opacity: 0;
                        }


                        .new-module-item:hover:before {
                            width: 100%;
                            left: 0;
                            opacity: 0.5;
                        }

                        .new-module-item:hover .del-btn {
                            opacity: 1;
                        }
                    }

                    .module-save-btn {
                        width: 60px;
                        height: 60px;
                        background: #409EFF;
                        border-radius: 10px;
                        color: #FFFFFF;
                        font-size: 14px;
                        //line-height: 60px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-content: center;
                        font-weight: 400;
                        //margin-right: 20px;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .adhibition-btn {
                        width: 60px;
                        height: 60px;
                        background: #409EFF;
                        border-radius: 10px;
                        color: #FFFFFF;
                        font-size: 14px;
                        text-align: center;
                        font-weight: 400;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 0 20px;
                    }
                }

                .featured-topbox {
                    z-index: 1;
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    padding: 30px 30px 30px 0;
                    position: relative;
                    background: #FFFFFF;
                    box-shadow: 0px 5px 10px 1px rgba(32, 32, 32, 0.05);

                    span {
                        display: inline-block;
                        line-height: 1;
                        font-size: 14px;
                        padding: 8px 12px;
                        border-radius: 4px;
                        font-weight: 400;
                        cursor: pointer;
                    }

                    .class-item {
                        background: #409EFF;
                        box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                        border-radius: 20px;
                        color: #ffffff;
                    }
                }

                .featured-top {
                    flex: 1;
                    height: 1%;
                    background: #ffffff;

                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                        margin-right: -37px !important;
                    }

                    ::v-deep .el-scrollbar__view {
                        margin-right: 20px;
                    }

                    .featured-mainbox {
                        width: 100%;

                        .mainbox-list {
                            min-height: 425px;
                            padding: 30px 60px;

                            .dragg-box {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                            }

                            .mainbox-list-item {
                                cursor: pointer;
                                width: 50%;
                                margin-bottom: 50px;
                                display: flex;

                                .item-title {
                                    margin-left: 30px;
                                    display: flex;
                                    align-items: center;
                                }
                            }

                            .no-data {
                                width: 100%;
                                font-size: 16px;
                                text-align: center;
                            }
                        }

                        .page-box {
                            text-align: center;
                        }
                    }
                }
            }

            .featured-right {
                width: 230px;
                min-width: 189px;
                background-color: #fff;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }

                .item-title {
                    position: relative;
                    font-size: 16px;
                    padding: 20px 20px 0;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 27px;
                        width: 6px;
                        height: 10px;
                        background: #409EFF;
                        border-radius: 3px;
                    }

                    .text {
                        margin-left: 15px;
                    }
                }

                .app-list {
                    padding: 0 20px;
                    display: flex;
                    flex-wrap: wrap;

                    .app-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 10px 16px 20px;
                        cursor: pointer;
                        border-radius: 10px;

                        &:hover {
                            transform: scale(1.2);
                            transition: 1s;
                            box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                            border-radius: 6px;
                        }

                        .app-cover {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 60px;
                            height: 60px;
                        }

                        img.app-icon {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 5px;
                        }

                        span.app-name {
                            color: #333;
                            font-size: 12px;
                            margin-top: 10px;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 160px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .upload-resources-dialog {
        .info-form {
            .file {
                ::v-deep .el-form-item__content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                }

                .file-name {
                    display: flex;
                    position: relative;
                }

                .upload-cover {
                    width: 100px;
                    height: 30px;
                    background: #ECF5FF;
                    border: 1px solid #DCECFE;
                    border-radius: 5px;
                    color: #409EFF;
                    line-height: 30px;
                    text-align: center;
                    margin-right: 15px;

                    i.iconfont {
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }

                .cover-input {
                    position: absolute;
                    top: 0;
                    width: 100px;
                    height: 30px;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            .resources-class-cascader {
                width: 100%;
            }

            .select-course {
                width: 100%;
            }
        }
    }

    .app-detail-dialog {
        ::v-deep .el-dialog {
            ::v-deep .el-dialog__header {
                border-bottom: 1px solid #e6e7ea;
            }

            .title {
                text-align: center;
                margin: 0;
            }

            .content {
                display: flex;
                flex-direction: column;

                .h2 {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    margin: 10px 0;
                }

                .soft-introduction {
                    text-align: center;
                }

                .soft-content {
                    padding: 10px 50px;
                    min-height: 200px;
                }
            }

            ::v-deep .el-dialog__footer {
                border-top: 1px solid #e6e7ea;
                padding: 20px;

                a {
                    padding: 7px 20px;
                    font-size: 14px;
                    border-radius: 4px;
                    margin-right: 20px;
                }

                a.home {
                    border: 1px solid #DCDFE6;
                    color: #606266;

                    &:hover {
                        color: #409EFF;
                        border-color: #c6e2ff;
                        background-color: #ecf5ff;
                    }
                }

                a.shop {
                    color: #FFF;
                    background-color: #409EFF;
                    border-color: #409EFF;

                    &:hover {
                        background: #66b1ff;
                        border-color: #66b1ff;
                        color: #FFF;
                    }
                }
            }
        }
    }

    ::v-deep .saveTemplate {
        .el-dialog__header {
            background: #409EFF;

            span {
                color: #fff;
                font-size: 16px;
            }

            .el-icon-close {
                color: #fff;
            }
        }

        .el-dialog__body {
            padding: 70px 0px 20px 50px;
        }

        .template-box {
            display: flex;
            flex-direction: column;

            .template-box-name {
                display: flex;
                align-items: center;
                color: #333333;
                font-size: 14px;
                padding-right: 60px;
            }

            .template-box-btn {
                margin-top: 70px;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;

                .el-button {
                    margin-left: 20px;
                }
            }
        }
    }

    .temp-popover {
        display: flex;
        flex-direction: column;

        .no-data {
            text-align: center;
        }

        .popover-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            height: 30px;

            &:hover {
                background: #ECF5FF;

                span {
                    color: #409EFF;
                }
            }

            div {
                color: #666666;
                font-size: 14px;
                padding-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            i {
                color: #666666;
                font-size: 12px;
                padding-right: 10px;
            }
        }
    }

    .select-positioning {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        box-shadow: 0px 1px 6px 1px rgba(64, 158, 255, 0.2);
        border-radius: 10px;
        margin-left: 20px;
        //position: absolute;
        //right: -20px;
        //top: -78px;
        //z-index: 99;
        background: #409EFF;

        i {
            font-size: 29px;
            color: #9CA9C3;
        }

        .hover-two {
            background: #409EFF;
            border-radius: 10px;
            color: #FFFFFF;
            font-size: 14px;
            //line-height: 60px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            font-weight: 400;
            cursor: pointer;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
</style>
