<template>
    <div class="textbook-content" :native="false">
        <el-scrollbar class="textbook-list">
            <div class="textbook">
                <div class="textbook-item" v-for="item in textbookList" v-if="textbookList.length > 0" @click="viewDetail(item)">
                    <div class="textbook-cover">
                        <img src="../../assets/img/image/textbook.png" alt="" class="textbook-icon">
                    </div>
                    <span class="textbook-name" :title="item.resource_title">{{item.resource_title}}</span>
                </div>
                <div v-if="textbookList.length === 0" class="no-data">
                    <span>暂无数据</span>
                </div>
            </div>
        </el-scrollbar>
        <ResourceDialog :dialogVisible="dialogVisible" :src="src" @closeDialog="closeDialog"></ResourceDialog>
    </div>
</template>

<script>
    import ResourceDialog from "../components/ResourceDialog";

    export default {
        name: "teacherTextbook",
        props: ['courseInfos', 'nodeIndex', 'chapterIndex', 'select_list', 'module_list', 'searchInput'],
        components: {
            ResourceDialog
        },
        data() {
            return {
                textbookList: [],
                src: "",
                dialogVisible: false,
            }
        },
        created() {
            this.getCourseInfo();
        },
        watch: {
            searchInput(d) {
                if(d !== ""){
                    let arr = [];
                    for (let i = 0; i < this.textbookList.length; i++) {
                        if (this.textbookList[i].resource_title.indexOf(d) !== -1) {
                            arr.push(this.textbookList[i]);
                        }
                    }
                    this.textbookList = arr;
                } else {
                    this.textbookList = [];
                    this.getCourseInfo();
                }
            }
        },
        methods: {
            //获取教材列表
            getCourseInfo() {
                let params = {
                    id: this.$route.query.course_id
                };
                this.$thttp.axiosGetBy(this.$api.courseinfo, params, (res) => {
                    if (res.code === 200) {
                        // console.log('教材',res.data.reslist)
                        for (let i = 0; i < res.data.reslist.length; i++) {
                            if (res.data.reslist[i].resource_type_id === 2) {
                                this.textbookList.push(res.data.reslist[i]);
                            }
                        }
                        this.textbookList.forEach(item => {
                            item.type = 2
                        })
                    }
                }, (error) => {
                    console.log(error);
                })
            },
            viewDetail(item) {
                this.src = item.file_preview_path;
                this.dialogVisible = true;
            },
            closeDialog() {
                this.src = '';
                this.dialogVisible = false
            },
        }
    }
</script>

<style scoped lang="scss">
    .textbook-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .textbook-list {
            flex: 1;
            width: 100%;
            height: 1%;
            background-color: #fff;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .textbook {
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;

                .textbook-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 30px 16px 0;
                    cursor: pointer;
                    border-radius: 10px;

                    &:hover {
                        transform: scale(1.2);
                        transition: 1s;
                    }

                    .textbook-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 107px;
                        height: 107px;
                    }

                    img.textbook-icon {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 5px;
                    }

                    span.textbook-name {
                        color: #333;
                        font-size: 12px;
                        margin-top: 10px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 107px;
                        text-align: center;
                    }
                }
            }
        }

        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
            background-color: #fff;

            .module {
                flex: 1;
                width: 1%;

                ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                    white-space: nowrap;
                }
            }

            .module-list {
                padding: 6px;
                display: flex;

                .module-item {
                    display: inline-block;
                    min-width: 60px;
                    min-height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 10px;
                    margin-right: 15px;
                }
            }

            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 4px;
                color: #409EFF;
                font-size: 14px;
                line-height: 60px;
                text-align: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            /*position: absolute;*/
            /*bottom: 20px;*/
            /*left: 0;*/
            /*right: 0;*/
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);

            .module {
                flex: 1;
                width: 1%;

                ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            .module-list {
                padding: 6px;
                display: flex;
                position: relative;

                .module-item {
                    width: 60px;
                    height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 10px;
                    margin-right: 15px;

                    .item-cover {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        border-radius: 50%;

                        i {
                            font-size: 20px;
                            color: #fff;
                        }
                    }

                    .item-cover1 {
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        text-align: center;
                        border-radius: 10px;

                        i {
                            font-size: 20px;
                            color: #fff;
                        }

                        .show-img {
                            width: 100%;
                            height: 100%;

                            .img-box {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }

                    .show1 {
                        background: #61BCEB;
                    }

                    .show2 {
                        background: #3DCAC8;
                    }

                    .show3 {
                        background: #F0BC49;
                    }

                    .show4 {
                        background: #EC75A4;
                    }

                    .show5 {
                        background: #79ACDC;
                    }

                    .show6 {
                        background: #61BCEB;
                    }

                    .show7 {
                        background: #5AB9E9;
                    }

                    .courseware {
                        background: linear-gradient(0deg, #F5D971, #EDA92E);
                    }

                    .textbook {
                        background: linear-gradient(0deg, #7BCBF2, #44ACE3);
                    }

                    .lesson-plan {
                        background: linear-gradient(0deg, #53DED1, #2BB9BF);
                    }

                    .micro-lesson {
                        background: linear-gradient(0deg, #EE89B5, #EA5D90);
                    }

                    .animation {
                        background: linear-gradient(0deg, #F39E72, #EC6C6C);
                    }

                    .test-center {
                        background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
                    }

                    .training-center {
                        background: linear-gradient(0deg, #ED83AA, #EC6B6F);
                    }

                    .knowledge-points {
                        background: linear-gradient(0deg, #77C9F1, #47AEE4);
                    }

                    .material-library {
                        background: linear-gradient(0deg, #51DCD0, #2CBAC0);
                    }

                    .case-library {
                        background: linear-gradient(0deg, #F5D971, #EDA92E);
                    }

                    .related-copywriting {
                        background: linear-gradient(0deg, #8BC3EB, #628EC9);
                    }

                    .personal-info {
                        background: linear-gradient(0deg, #51DCD0, #2FBDC1);
                    }
                }
            }

            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 4px;
                color: #409EFF;
                font-size: 14px;
                line-height: 60px;
                text-align: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
</style>