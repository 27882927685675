<template>
    <el-dialog
            title="鱼骨图"
            :visible.sync="fishVisible"
            :before-close="handleClose">
        <Jtopo :fishboneData="fishboneData"/>
    </el-dialog>
</template>

<script>
    import Jtopo from "../../components/Jtopo";

    export default {
        props: ['fishVisible', 'fishData'],
        components: {
            Jtopo
        },
        data() {
            return {
                fishboneData: {
                    name: "",
                    children: []
                }
            };
        },
        mounted() {
            this.formatFish();
        },
        methods: {
            handleClose() {
                this.$emit('showFish', false);
            },
            formatFish() {
                let len = this.fishData.length;
                this.fishboneData.name = this.fishData[len - 1].name;
                for (let i = 0; i < len - 1; i++) {
                    this.fishboneData.children.push(this.fishData[i]);
                }
            },
        }
    };
</script>

<style scoped lang="scss">
    ::v-deep .el-dialog {
        width: 1800px;
    }

    ::v-deep .el-dialog__body {
        width: calc(100% - 120px);
        min-width: 1000px;
        text-align: center;
    }

    ::v-deep .el-dialog__header::after {
        content: '';
        width: 100%;
        height: 10px;
        display: block;
        margin: 0 auto;
        border-bottom: 1px solid #EEEEEE;
    }

    .main-box {
        height: 680px;
        margin-top: 28px;
    }

    ::v-deep .el-dialog__footer {
        text-align: center;
    }

</style>