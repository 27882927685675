import axios from 'axios';
import { Loading } from 'element-ui';

const instance = axios.create({
    // baseURL: 'http://yike.e-class.me/',
    // timeout: 3000
});

const ins = axios.create({
    // baseURL: 'http://yike.e-class.me/',
    // timeout: 3000
});

let loadingInstance = null;

/* 请求拦截器 */
instance.interceptors.request.use((config) => {
    loadingInstance =  Loading.service({
        lock: true,
        text: 'loading',
        spinner: 'el-icon-loading',
    });
    if (localStorage.getItem('sToken')) {
        config.headers.Authorization = localStorage.getItem('sToken');
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

/* 响应拦截器 */

instance.interceptors.response.use((response) => {
    loadingInstance.close();
    return response.data;
}, (error) => {
    loadingInstance.close();
    return Promise.reject(error);
});

/* 请求拦截器 */
ins.interceptors.request.use((config) => {
    if (localStorage.getItem('sToken')) {
        config.headers.Authorization = localStorage.getItem('sToken');
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

/* 响应拦截器 */

ins.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    loadingInstance.close();
    return Promise.reject(error);
});


const Http = {};

/* get方法参数以param对象传参 */
Http.axiosGetBy = (url, params, callback, reject) => {
    instance.get(url, {params: params}).then(callback).catch(reject);
}

/* get方法参数跟在url中 */
Http.axiosGet = (url, callback, reject) => {
    instance.get(url).then(callback).catch(reject);
}

/* post方法 */
Http.axiosPost = (url, params, callback, reject) => {
    instance.post(url, params).then(callback).catch(reject);
}

/* 上传文件 */
Http.axiosFile = (url, formData, callback, reject) => {
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    instance.post(url, formData, config).then(callback).catch(reject);
}

Http.aPost = (url, params, callback, reject) => {
    ins.post(url, params).then(callback).catch(reject);
}
Http.aGet = (url, callback, reject) => {
    ins.get(url).then(callback).catch(reject);
}

Http.axiosFile = (url, formData, callback, reject) => {
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    instance.post(url, formData, config).then(callback).catch(reject);
}

export default Http;
