<template>
    <div class="quality-course-container">
        <HeaderModule></HeaderModule>
        <div class="student-main-body">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import HeaderModule from 'components/qualityCourseLayout/HeaderModule';

    export default {
        name: "LayoutContent",
        components: {
            HeaderModule
        }
    }
</script>

<style lang="scss" scoped>
    .quality-course-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
        .student-main-body {
            flex: 1;
            height: 1%;
            background-color: #F2F2F2;
            overflow: auto;
            /*display: flex;*/
            /*flex: 1;*/
            /*justify-content: center;*/
            /*align-items: flex-start;*/
            /*height: calc(100% - 60px);*/
            /*background-color: #F2F2F2;*/
            /*box-sizing: border-box;*/
            /*overflow: auto;*/
        }
    }
</style>